import $ from 'jquery';
import React, { Component, useEffect } from 'react';
import { _ref, _space, _docReady, Header } from '../../utils';

// STYLE
import "../../Assets/css/styles.css";
import "../../Assets/css/slider.css";
import "../../Assets/css/custom.scss";
import "../../Assets/css/hero.scss";
import "../../Assets/css/c45.scss";
import "../../Assets/css/animate.scss";
import "../../Assets/css/response.scss";
import "../../Assets/css/home.scss";
import "../../Assets/css/page.scss";
import "../../Assets/css/1024.scss";
import "../../Assets/css/motion.scss";
import "../../Assets/css/coin.scss";
import "../../Assets/css/flag.scss";
import "../../Assets/css/a.css";

function Container (props) {
  useEffect(() => {
    $("#root").attr("class", props.page+'-page');
    _docReady(props.page, "go");
  });

  return (
    <>
        <Header page={props.page} />
        {props.children}
        <span class="scroll_top">&#8593;</span>
        <div id="info-div">
          <span className='fi1'></span>  
          <span className='fi2'></span>  
          <span className='fi4'></span>  
        </div>
    </>
  );
}

export default Container;