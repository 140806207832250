import $ from 'jquery';
import React, { lazy, Suspense, Component } from 'react';
import Avatar from 'react-avatar';
import Myslider from './App/Pages/Slider';

// Hero
import _pa from "./Assets/img/pa.svg";
import mobh from "./Assets/img/home/mobh.svg";
import ic_love from "./Assets/img/home/ic_love_m.png";
import ic_face from "./Assets/img/home/ic_face_m.svg";

// Trust Pilot
import star from "./Assets/img/star.svg";
import tpi from "./Assets/img/tpi.svg";
import tpiAlt from "./Assets/img/tpi-alt.svg";

// ICONS
import kpi from "./Assets/img/kpi1.svg";
import kpi_alt from "./Assets/img/kpi2.svg";
// import bg from "./Assets/img/bg.png";
import ud from "./Assets/img/ud.png";
import _i from "./Assets/img/instagram-alt.svg";
import _f from "./Assets/img/facebook-alt.svg";
import _y from "./Assets/img/youtube-alt.svg";
import i_dark from "./Assets/img/instagram.svg";
import f_dark from "./Assets/img/facebook.svg";
import y_dark from "./Assets/img/youtube.svg";
import air from "./Assets/img/air.png";
import lac from "./Assets/img/lac.png";
import minus from "./Assets/img/minus.svg";
import plus from "./Assets/img/plus.svg";

// MENU
import f_m1 from "./Assets/img/menu-f/m1.svg";
import f_m2 from "./Assets/img/menu-f/m2.svg";
import f_m3 from "./Assets/img/menu-f/m3.svg";
import f_m4 from "./Assets/img/menu-f/m4.svg";
// import f_m5 from "./Assets/img/menu-f/m5.svg";
import f_m6 from "./Assets/img/menu-f/m6.svg";
import f_m7 from "./Assets/img/menu-f/m7.svg";
// import f_m8 from "./Assets/img/menu-f/m8.svg";

import r_m1 from "./Assets/img/menu-r/m1.svg";
// import r_m2 from "./Assets/img/menu-r/m2.svg";
// import r_m3 from "./Assets/img/menu-r/m3.svg";
// import r_m4 from "./Assets/img/menu-r/m4.svg";
import r_m5 from "./Assets/img/menu-r/m5.svg";
import r_m6 from "./Assets/img/menu-r/m6.svg";

import close from "./Assets/img/close.png";
import jam from "./Assets/img/menu.svg";

// FLAGS
import usa from "./Assets/img/usa.svg";
import uk from "./Assets/img/United-States.png";
import spain from "./Assets/img/Spain.png";
import italy from "./Assets/img/Italy.png";
import germany from "./Assets/img/Germany.png";
import france from "./Assets/img/France.png";

// Masthead
import pll from "./Assets/img/ple-light.png";
import pld from "./Assets/img/ple-dark.png";
import ple from "./Assets/img/ple-sm.svg";

// Partner
import line from "./Assets/img/line.png";

// Testimony
// import tes1 from "./Assets/img/tes1.png";
// import tes2 from "./Assets/img/tes2.png";
// import tes3 from "./Assets/img/tes3.png";
import tesbd from "./Assets/img/tesbd.png";
import tesbl from "./Assets/img/tesbl.png";

// Table
import monitor from "./Assets/img/monitor.png";
import monitor2 from "./Assets/img/monitor-alt.png";
import tup from "./Assets/img/tup.png";
import tup2 from "./Assets/img/tup-alt.png";

import vr from "./Assets/img/vr.png";
import vl from "./Assets/img/vl.png";

import cld_1 from "./Assets/img/clients/cld/cl-1.png";
import cld_2 from "./Assets/img/clients/cld/cl-2.png";
import cld_3 from "./Assets/img/clients/cld/cl-3.png";
import cld_4 from "./Assets/img/clients/cld/cl-4.png";
import cld_5 from "./Assets/img/clients/cld/cl-5.png";
import cld_6 from "./Assets/img/clients/cld/cl-6.png";
import cld_7 from "./Assets/img/clients/cld/cl-7.png";
import cld_8 from "./Assets/img/clients/cld/cl-8.png";
import cld_9 from "./Assets/img/clients/cld/cl-9.png";
import cld_10 from "./Assets/img/clients/cld/cl-10.png";
import cld_11 from "./Assets/img/clients/cld/cl-11.png";

import cl_1 from "./Assets/img/clients/cl-1.png";
import cl_2 from "./Assets/img/clients/cl-2.png";
import cld2 from "./Assets/img/clients/cl-2-alt.png";
import cl_3 from "./Assets/img/clients/cl-3.png";
import cl_4 from "./Assets/img/clients/cl-4.png";
import cld4 from "./Assets/img/clients/cl-4-alt.png";
import cl_5 from "./Assets/img/clients/cl-5.png";
import cl_6 from "./Assets/img/clients/cl-6.png";
import cl_7 from "./Assets/img/clients/cl-7.png";
import cl_8 from "./Assets/img/clients/cl-8.png";
import cl_9 from "./Assets/img/clients/cl-9.png";
import cl_10 from "./Assets/img/clients/cl-10.png";
import cl_11 from "./Assets/img/clients/cl-11.png";

const log = console.log;

function _getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return cname == "theme" ? "light" : "";
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function _ref (e, x, y) {
    return e && e.style.setProperty(x, y, "important");
}

function _space (x = 0) {
    let id = Math.floor(Math.random() * 100);
    return (
        <>
            <span className={'_redsp _responsive'+(x == 0 ? ' bresp' : '')} id={"n-"+id}>&nbsp;</span>
            <br className={"not-responsive"+(x == 0 ? ' nbresp' : '')} id={"s-"+id} />
        </>
    )
    // return (
    //     <>
    //         <br className={"not-responsive"+(x == 0 ? ' nbresp' : '')} /><span className={'responsive'+(x == 0 ? ' bresp' : '')}>&nbsp;</span>
    //     </>
    // )
}

function _space2 (x = 0) {
    return (
        <>
            <br className='_bresp' /><span className='_nbresp'>&nbsp;</span>
        </>
    )
}

function _docReady (page = "", _go = null) {
    if(_go == null || $('body').hasClass('loaded')) {
        console.log("return false", page, _go);
        return false;
    }
    $(function () {
        console.log("ready", page, _go);

        // Ready function
        if($("body").hasClass("loaded") || !$("#loader-wrapper").length) {
            $("body").css("overflow", "auto");
        }
        if($("[data-theme]").attr("data-theme") == "dark") {
            $(".switch input").prop("checked", true);
            $("html").attr("style", "background: #181c25 !important;");
        } else {
            $(".switch input").prop("checked", false);
            $("html").attr("style", "background: #fff !important;");
        }

        colrez();
        _resized();

        setTimeout(() => {
            $('body').addClass('loaded');
            $("body").css("overflow", "auto");
            setCookie("loaded", 'home', 365);

            // ava_scroll();

            setTimeout(() => {
                $("#loader-wrapper").remove();
                colrez();
                if(page == "premium") {
                    preple();
                }
            }, 250);
        }, 250);

        const navbarToggler = document.body.querySelector('.navbar-toggler');
        const responsiveNavItems = [].slice.call(document.querySelectorAll('#navbarResponsive .nav-link'));
        responsiveNavItems.map(function (responsiveNavItem) {
            responsiveNavItem.addEventListener('click', () => {
                if (window.getComputedStyle(navbarToggler).display !== 'none') {
                    navbarToggler.click();
                }
            });
        });

        let scrolled = false;
        let scroll_c = false;
        let scroll_c1 = false;
        let scroll_c2 = false;
        let scroll_c4 = false;
        let scroll_s4 = false;
        let scroll_t2 = false;
        let scroll_cs = false;
        let _dist = 300;
        
        window.scrollTo(window.scrollX, window.scrollY - 2);
        window.scrollTo(window.scrollX, window.scrollY + 2);
        
        // App Events
        $(window).scroll(function (e) {
            if(_go == "go") {
                console.log(page, "--onscroll {window}");
                ava_scroll(e);
                if($("#app-overlay").hasClass("active") && $(window).innerWidth() > 1170) {
                    close_menu();
                }
                if(window.scrollY > 700) {
                    $('.scroll_top').addClass("active");
                } else {
                    $('.scroll_top').removeClass("active");
                }
            }
            if(page == "home" && _go == "go") {
                if($(window).innerWidth() < 767) {
                    let mh = $(".man.fst").innerWidth() * 1.333;
                    $(".man.fst").css("height", mh+"px");
                }
            }
            if(page == "pricing" && _go == "go") {
                let y = $(".prisec.m_t")[0].getBoundingClientRect().y;
                if(y < 50) {
                    $(".menusect").addClass("stick");
                    $(".prisec.m_t").addClass("stk");
                } else {
                    $(".menusect").removeClass("stick");
                    $(".prisec.m_t").removeClass("stk");
                }
            }
        });

        $('.scroll_top').on("click", function () {
            window.scrollTo(0, 0);
        });
        
        // $("#root").on("scroll", function (e) {
        //     if(_go == "go" && page != "pricing") {
        //         console.log(page, "--onscroll {root}");
        //         if($("#app-overlay").hasClass("active")) {
        //             close_menu();
        //         }
        //         ava_scroll(e);
        //     }
        //     if(page == "home" && _go == "go") {
        //         if($(window).innerWidth() < 767) {
        //             let mh = $(".man.fst").innerWidth() * 1.333;
        //             $(".man.fst").css("height", mh+"px");
        //         }
        //     }
        //     if(page == "pricing" && _go == "go") {
        //         let y = $(".prisec.m_t")[0].getBoundingClientRect().y;
        //         if(y < 50) {
        //             $(".menusect").addClass("stick");
        //         } else {
        //             $(".menusect").removeClass("stick");
        //         }
        //     }
        // });

        $(window).resize(() => {
            // console.log(page, "--onresize {window}");
            // if($(window).innerHeight() > $("#root").innerHeight()) {
            //     $("#root").css({height: $(window).innerHeight()+"px"});
            // }
            if(_go == "go" && $(window).innerWidth() > 992) {
                _resized();
                colrez();
                if(page == "pricing") {
                    if($(window).innerWidth() > 940) {
                        $(".list_w").scrollLeft(0);
                    } else {
                        $("._carvl, ._carvr").removeAttr("disabled");
                    }
                }
            }
        });

        $(".swae").on("click", () => {
            $(".cale-pop").addClass("show");
            $("#root > *:not(#app-overlay, .cale-pop)").addClass("bluryy");
        });

        $(".cale-pop").on("click", (e) => {
            if($(e.target).hasClass("cale-pop")) {
                $(".cale-pop").removeClass("show");
                $(".bluryy").removeClass("bluryy");
            }
        });

        $(".cal-can").on("click", (e) => {
            $(".cale-pop").removeClass("show");
            $(".bluryy").removeClass("bluryy");
        });

        $(".rdm").on("click", function () {
            let ate = (page == "home" || page == "pricing");
            let tpb = $(this).parent();
            let _spt = ate ? '' : '.stopad';
            console.log(tpb, tpb.hasClass("r"))
            if(tpb.hasClass("r")) {
                $(".testimony .tpb.r").removeClass("r");
                if(!ate) {
                    $(".testimony"+_spt).removeClass('_nx').addClass("_mx");
                    $(".testimony"+_spt).removeAttr('style');
                }
            } else {
                $(".testimony .tpb.r").removeClass("r");
                tpb.addClass("r");
                if(!ate) {
                    let height = (600 + tpb.innerHeight() - 320)+"px";
                    $(".testimony"+_spt).removeClass('_mx').addClass("_nx");
                    $(".testimony"+_spt)[0].setAttribute('style', 'height: '+height+' !important');
                }
            }
        });

        // App Functions
        function _resized () {
            window.scrollTo(window.scrollX, window.scrollY - 2);
            window.scrollTo(window.scrollX, window.scrollY + 2);
            // ava_scroll();
            if($(window).innerWidth() <= 992) {
                $(".slide-left").removeClass("slide-left").addClass("fade-in").addClass("sleft");
                $(".slide-right").removeClass("slide-right").addClass("fade-in").addClass("sright");
            } else {
                $(".sleft").removeClass("fade-in").addClass("slide-left").removeClass("sleft");
                $(".sright").removeClass("fade-in").addClass("slide-right").removeClass("sright");
            }
        }

        function close_menu () {
            $("body").removeClass("menu_ed").css("overflow", "auto");
            $(".blurry").removeClass("blurry");
            $(".app-menu.active, .ud").removeClass("active");
            $("#app-overlay, #app-overlay-alt").removeClass("active").removeClass("mobile");
        }
        
        function _getTO (s, t = 1) {
            let _t = (t > 1 ? ($(s)[0].getBoundingClientRect().height/2) : t);
            return (window.screen.height > t+$(s)[0].getBoundingClientRect().bottom);
        }

        function colrez () {
            $(".colsec").each(function (k, v) {
                // let _ol = $(v).find(".nopd2")[0].offsetLeft;
                // let _wi = $(v).innerWidth();
                let trust = $(this).hasClass("trust");
                $(v).find("._responsive.bresp").each(function (m, n) {
                    $(n).parent().find("#"+(n.id.replace("n", "s"))).removeClass("hide");
                    if(n.offsetLeft < (trust ? 300 : 1000)) {
                        $(n).parent().find("#"+(n.id.replace("n", "s"))).addClass("hide");
                    }
                    // if(trust) {
                    //     log(n.id, n.offsetLeft, n.offsetParent.offsetLeft);
                    // } else {
                    // }
                });
            });
        }

        function preple () {
            setTimeout(() => {
                if($(".hero-pbg").hasClass("anm")) {
                    $(".hero-pbg").removeClass("anm");
                    console.log("removing...")
                    setTimeout(() => {
                        preple();
                    }, 5000);
                } else {
                    $(".hero-pbg").addClass("anm");
                    setTimeout(() => {
                        $(".hero-pbg.anm .cn-1").addClass("pus");
                        setTimeout(() => {
                            preple();
                        }, 4000);
                    }, 4000);
                }
            }, 100);
        }

        function sohert () {
            let _il_ = 0;
            var love = setInterval(function() {
                var r_num = Math.floor(Math.random() * 40) + 1;
                var r_size = Math.floor(Math.random() * 10) + 10;
                var r_left = Math.floor(Math.random() * 100) + 1;
                var r_bg = Math.floor(Math.random() * 25) + 100;
                var r_time = Math.floor(Math.random() * 5) + 5;
                var l_id = 'bh_'+_il_;

                $('.bg_heart').append("<div class='lheart "+l_id+"' style='width:" + r_size + "px;height:" + r_size + "px;left:" + r_left + "%;background:rgba(255," + (r_bg - 25) + "," + r_bg + ",1);-webkit-animation:love " + r_time + "s ease;-moz-animation:love " + r_time + "s ease;-ms-animation:love " + r_time + "s ease;animation:love " + r_time + "s ease'></div>");
                $('.bg_heart').append("<div class='lheart "+l_id+"' style='width:" + (r_size - 10) + "px;height:" + (r_size - 10) + "px;left:" + (r_left + r_num) + "%;background:rgba(255," + (r_bg - 25) + "," + (r_bg + 25) + ",1);-webkit-animation:love " + (r_time + 5) + "s ease;-moz-animation:love " + (r_time + 5) + "s ease;-ms-animation:love " + (r_time + 5) + "s ease;animation:love " + (r_time + 5) + "s ease'></div>");
                $('.lheart').each(function() {
                    var top = $(this).css("top").replace(/[^-\d\.]/g, '');
                    var width = $(this).css("width").replace(/[^-\d\.]/g, '');
                    if (top <= -100 || width >= 150) {
                        $(this).detach();
                    }
                });

                setTimeout(() => {
                    // console.log($(".lheart").length, "--le", "c:", ".lheart."+l_id);
                    $(".lheart."+l_id).remove();
                }, 5000);

                ++_il_;
            }, 750);
        }
        
        function ava_scroll () {
            if($("#swom").length) {
                if(_getTO("#swom", -100) && !scrolled) {
                    const calendar_int = () => {
                        scrolled = true;
                        let di = 1, ab = "a";
                        let date_shuffle = setInterval(() => {
                            if((di <= 8 && ab == "a") || (di > 6 && ab == "b")) {
                                $(".date-int:eq("+(ab == "a" ? (di - 1) : (di + 1))+")").removeClass("hover");
                                setTimeout(() => {
                                    if($(".available-days").hasClass("js-scroll")) {
                                        $(".available-days.js-scroll").removeClass("js-scroll").removeClass("fade-in");
                                    }
                                    $(".date-int:eq("+di+")").addClass("hover");
                                    if(di == 8) {
                                        ab = "b";
                                    }
                                    di = ab == "a" ? (di + 1) : (di - 1);
                                }, 100);
                            } else {
                                di = 1;
                                $(".date-int:eq(7)").removeClass("hover");
                                $(".date-int:eq(7)").addClass("active");
                                clearInterval(date_shuffle);
                                $(".time-div").css({opacity: 1});
                                let time_shuffle = setInterval(() => {
                                    if((di <= 6 && ab == "b") || (di > 3 && ab == "c")) {
                                        if(ab == "b" && di > 2) {
                                            $(".time-int:eq("+(di - 3)+")").addClass("hide");
                                            $(".time-int:eq("+di+")").removeClass("hide");
                                        }
                                        $(".time-int:eq("+(ab == "b" ? (di - 1) : (di + 1))+")").removeClass("hover");
                                        setTimeout(() => {
                                            $(".time-int:eq("+di+")").addClass("hover");
                                            if(di == 6) {
                                                ab = "c";
                                            }
                                            di = ab == "b" ? (di + 1) : (di - 1);
                                        }, 100);
                                    } else {
                                        di = 1;
                                        $(".time-int:eq(4)").removeClass("hover");
                                        $(".time-int:eq(4)").addClass("active");
                                        clearInterval(time_shuffle);
                                        setTimeout(() => {
                                            $('.time-int').removeClass("hide").removeClass("active");
                                            $('.time-int.h').addClass("hide");
                                            $(".time-div").css({opacity: 0});
                                            calendar_int();
                                        }, 1000);
                                    }
                                }, 500);
                            }
                        }, 350);
                    };
                    calendar_int();
                }
            }

            if($("#stats").length) {
                if(page == "home") {
                    if(_getTO("#stats", -100) && !scroll_c) {
                        scroll_c = true;
                        $(".counter").each(function () {
                            var count = $(this);
                            var countTo = count.attr('data-count');
                            // console.log(countTo);
                            $({countNum:count.text()}).animate({countNum:countTo}, {
                                duration: 2500,
                                easing:'linear',
                                step:function(){
                                    count.text(Math.floor(this.countNum));
                                },
                                complete:function(){
                                    count.text(this.countNum);
                                }
                            });
                        });
                    }
                } else {
                    if(_getTO("#stats", -100) && !scroll_cs) {
                        $("#stats").find(".mt-5, .lintr, .lintl, .lintd").addClass("anm");
                        scroll_cs = true;
                    }
                }
            }

            if($("#colsec-3").length && page == "sales") {
                if(_getTO("#colsec-3", -150) && !scroll_c) {
                    scroll_c = true;
                    $(".counter").each(function () {
                        var count = $(this);
                        var countTo = count.attr('data-count');
                        // console.log(countTo);
                        $({countNum:count.text()}).animate({countNum:countTo}, {
                            duration: 2500,
                            easing:'linear',
                            step:function(){
                                count.text(Math.floor(this.countNum));
                            },
                            complete:function(){
                                count.text(this.countNum);
                            }
                        });
                    });
                }
            }

            if($(".cm-box-2.tpb.r").length) {
                if($(".cm-box-2.tpb.r")[0].getBoundingClientRect().y < -250 || $(".cm-box-2.tpb.r")[0].getBoundingClientRect().y >= window.screen.height) {
                    $(".cm-box-2.tpb.r .rdm").click();
                }
            }

            if($(".cm-box.tpb.r").length) {
                if($(".cm-box.tpb.r")[0].getBoundingClientRect().y < -250 || $(".cm-box.tpb.r")[0].getBoundingClientRect().y >= window.screen.height) {
                    $(".cm-box.tpb.r .rdm").click();
                }
            }
            
            if($("figure.fig-1").length) { // Leads
                $("#info-div .fi1").html("c-1: "+_getTO("#colsec-1", -200)+" ("+window.screen.height+") ("+$("#colsec-1")[0].getBoundingClientRect().bottom+")");
                if(_getTO("#colsec-1", -200) && !scroll_c1 && !$("figure.fig-1").hasClass("anm")) {
                    scroll_c1 = true;
                    $("figure.fig-1").addClass("anm");
                }
            }
            
            if($("figure.tig-0").length) { // Appointment
                $("#info-div .fi1").html("c-1: "+_getTO("#colsec-1", -200)+" ("+window.screen.height+") ("+$("#colsec-1")[0].getBoundingClientRect().bottom+")");
                if(_getTO("#colsec-1", -200) && !scroll_c1 && !$("figure.tig-0").hasClass("anm")) {
                    scroll_c1 = true;
                    let scope_up = () => {
                        setTimeout(() => {
                            $("figure.tig-0").addClass("anm");
                            setTimeout(() => {
                                $("figure.tig-0").removeClass("anm");
                                setTimeout(() => {
                                    scope_up();
                                }, 5 * 1000);
                            }, 2000);
                        }, 1000);
                    }
                    scope_up();
                }
            }
            
            if($("figure.aig-1").length) { // Sass
                $("#info-div .fi1").html("c-1: "+_getTO("#colsec-1", -200)+" ("+window.screen.height+") ("+$("#colsec-1")[0].getBoundingClientRect().bottom+")");
                if(_getTO("#colsec-1", -200) && !scroll_c1 && !$("figure.aig-1").hasClass("anm")) {
                    scroll_c1 = true;
                    setTimeout(() => {
                        $("figure.aig-1").addClass("anm");
                    }, 1000);
                }
            }
            
            if($("figure.wig-1").length) { // Premium
                $("#info-div .fi1").html("c-1: "+_getTO("#colsec-1", -200)+" ("+window.screen.height+") ("+$("#colsec-1")[0].getBoundingClientRect().bottom+")");
                if(_getTO("#colsec-1", -200) && !scroll_c1 && !$("figure.wig-1").hasClass("anm")) {
                    scroll_c1 = true;
                    setTimeout(() => {
                        $("figure.wig-1").addClass("anm");
                    }, 1000);
                }
            }
            
            if($("figure.mig-1").length) { // Social
                $("#info-div .fi1").html("c-1: "+_getTO("#colsec-1", -200)+" ("+window.screen.height+") ("+$("#colsec-1")[0].getBoundingClientRect().bottom+")");
                if(_getTO("#colsec-1", -200) && !scroll_c1 && !$("figure.mig-1").hasClass("anm")) {
                    scroll_c1 = true;
                    let tsp = 750;

                    let chatfn = (csa = '_1') => {
                        setTimeout(() => {
                            $(".chiw."+csa).css({display: "flex", opacity: 1});
                            setTimeout(() => {
                                $(".chiw."+csa+" .cmsg.dots").addClass("hide");
                                $(".chiw."+csa+" .cmsg._1").removeClass("hide");
                                if(csa == '_3' && _go == "go") {
                                    sohert();
                                }
                                setTimeout(() => {
                                    $(".chiw."+csa+" .cmsg.dots").removeClass("hide");
                                    setTimeout(() => {
                                        $(".chiw."+csa+" .cmsg.dots").addClass("hide");
                                        $(".chiw."+csa+" .cmsg._2").removeClass("hide");
                                        if(csa == '_1') {
                                            chatfn('_2');
                                        }
                                        if(csa == '_2') {
                                            chatfn('_3');
                                        }
                                    }, tsp);
                                }, tsp);
                            }, tsp);
                        }, 250);
                    };

                    setTimeout(() => {
                        chatfn();
                    }, 750);
                }
            }

            if($("figure.fig-2").length) {
                $("#info-div .fi2").html("c-2: "+_getTO("#colsec-2", -200)+" ("+window.screen.height+") ("+$("#colsec-2")[0].getBoundingClientRect().bottom+")");
                if(_getTO("#colsec-2", -200) && !scroll_c2 && !$("figure.fig-2").hasClass("anm")) {
                    scroll_c2 = true;
                    $("figure.fig-2").addClass("anm");
                }
            }

            if($("figure.tig-1").length) { // Appointment
                $("#info-div .fi2").html("c-2: "+_getTO("#colsec-2", -200)+" ("+window.screen.height+") ("+$("#colsec-2")[0].getBoundingClientRect().bottom+")");
                if(_getTO("#colsec-2", -200) && !scroll_c2 && !$("figure.tig-1").hasClass("anm")) {
                    scroll_c2 = true;
                    setTimeout(() => {
                        $("figure.tig-1").addClass("anm");
                    }, 250);
                }
            }

            if($("figure.aig-2").length) { // Sass
                $("#info-div .fi2").html("c-2: "+_getTO("#colsec-2", -200)+" ("+window.screen.height+") ("+$("#colsec-2")[0].getBoundingClientRect().bottom+")");
                if(_getTO("#colsec-2", -200) && !scroll_c2 && !$("figure.aig-2").hasClass("anm")) {
                    scroll_c2 = true;
                    setTimeout(() => {
                        $("figure.aig-2").addClass("anm");
                        setTimeout(() => {
                            $("figure.aig-2").addClass("us");
                        }, 1500);
                    }, 50);
                }
            }

            if($("figure.wig-2").length) { // Premium
                $("#info-div .fi2").html("c-2: "+_getTO("#colsec-2", -200)+" ("+window.screen.height+") ("+$("#colsec-2")[0].getBoundingClientRect().bottom+")");
                if(_getTO("#colsec-2", -200) && !scroll_c2 && !$("figure.wig-2").hasClass("anm")) {
                    scroll_c2 = true;
                    setTimeout(() => {
                        $("figure.wig-2").addClass("anm");
                    }, 50);
                }
            }

            if($("figure.mig-2").length) { // Social
                $("#info-div .fi2").html("c-2: "+_getTO("#colsec-2", -200)+" ("+window.screen.height+") ("+$("#colsec-2")[0].getBoundingClientRect().bottom+")");
                if(_getTO("#colsec-2", -200) && !scroll_c2 && !$("figure.mig-2").hasClass("anm")) {
                    scroll_c2 = true;
                    setTimeout(() => {
                        $("figure.mig-2").addClass("anm");
                    }, 50);
                }
            }

            if($("figure.wig-3").length) { // Premium
                $("#info-div .fi3").html("c-3: "+_getTO("#colsec-3", -200)+" ("+window.screen.height+") ("+$("#colsec-3")[0].getBoundingClientRect().bottom+")");
                if(_getTO("#colsec-3", -200) && !scroll_c && !$("figure.wig-3").hasClass("anm")) {
                    scroll_c = true;
                    setTimeout(() => {
                        $("figure.wig-3").addClass("anm");
                        setTimeout(() => {
                            $("figure.wig-3").addClass("us");
                        }, 1500);
                    }, 50);
                }
            }

            if($("figure.mig-3").length) { // Social
                $("#info-div .fi3").html("c-3: "+_getTO("#colsec-3", -200)+" ("+window.screen.height+") ("+$("#colsec-3")[0].getBoundingClientRect().bottom+")");
                if(_getTO("#colsec-3", -200) && !scroll_c && !$("figure.mig-3").hasClass("anm")) {
                    scroll_c = true;
                    setTimeout(() => {
                        $("figure.mig-3 .cil.hver").removeClass("hver");
                        $("figure.mig-3 .cil._2").addClass("hver");
                        $("figure.mig-3 .balle-sm").css({top: "255px", trasition: "1.3s ease-in all"});
                        setTimeout(() => {
                            $("figure.mig-3 .cil.hver").removeClass("hver");
                            $("figure.mig-3 .cil._3").addClass("hver");
                            setTimeout(() => {
                                $("figure.mig-3 .cil.hver").removeClass("hver");
                                $("figure.mig-3 .cil._4").addClass("hver");
                                setTimeout(() => {
                                    $("figure.mig-3 .cil.hver").removeClass("hver");
                                    $("figure.mig-3 .cil._3").addClass("hver");
                                    $("figure.mig-3 .balle-sm").css({top: "139px", trasition: ".7s ease-in all"});
                                    setTimeout(() => {
                                        $("figure.mig-3 .cil.hver").removeClass("hver");
                                        $("figure.mig-3 .cil._2").addClass("hver");
                                        setTimeout(() => {
                                            $("figure.mig-3 .cigl, figure.mig-3 .balle, figure.mig-3 .ppp, figure.mig-3 .balle-sm")
                                            .css({trasition: "1s ease-in all"})
                                            .addClass("anm");
                                            setTimeout(() => {
                                                $("figure.mig-3 .cil.hver").addClass("anm");
                                                $("figure.mig-3 .balle-sm").removeClass("anm").addClass("anm-2");
                                            }, 1000);
                                        }, 750);
                                    }, 500);
                                }, 500);
                            }, 500);
                        }, 500);
                    }, 500);
                }
            }

            if($("figure.fig-4").length) {
                $("#info-div .fi4").html("c-4: "+_getTO("#colsec-4", -200)+" ("+window.screen.height+") ("+$("#colsec-4")[0].getBoundingClientRect().bottom+")");
                if(_getTO("#colsec-4", -200) && !scroll_c4 && !$("figure.fig-4").hasClass("anm")) {
                    scroll_c4 = true;
                    $("figure.fig-4").addClass("anm");
                }
            }

            if($("figure.tig-4").length) { // Appointment
                $("#info-div .fi4").html("c-4: "+_getTO("#colsec-4", -200)+" ("+window.screen.height+") ("+$("#colsec-4")[0].getBoundingClientRect().bottom+")");
                if(_getTO("#colsec-4", -200) && !scroll_c4 && !$("figure.tig-4").hasClass("anm")) {
                    scroll_c4 = true;
                    $(window).trigger("fbook");
                }
            }

            if($("figure.aig-4").length) { // Sass
                $("#info-div .fi4").html("c-4: "+_getTO("#colsec-4", -200)+" ("+window.screen.height+") ("+$("#colsec-4")[0].getBoundingClientRect().bottom+")");
                if(_getTO("#colsec-4", -200) && !scroll_c4 && !$("figure.aig-4").hasClass("anm")) {
                    scroll_c4 = true;
                    $(window).trigger("lcloud");
                }
            }

            if($("figure.mig-4").length) { // Social
                $("#info-div .fi4").html("c-4: "+_getTO("#colsec-4", -200)+" ("+window.screen.height+") ("+$("#colsec-4")[0].getBoundingClientRect().bottom+")");
                if(_getTO("#colsec-4", -200) && !scroll_c4 && !$("figure.mig-4").hasClass("anm")) {
                    scroll_c4 = true;
                    $("figure.mig-4").addClass("anm");
                    setTimeout(() => {
                        $(".sp-count").each(function () {
                            var count = $(this);
                            var countTo = count.attr('data-count');
                            $({countNum:count.text()}).animate({countNum:countTo}, {
                                duration: 3000,
                                easing:'linear',
                                step:function(){
                                    let rand = Math.floor(Math.random() * 90)+10;
                                    count.text(Math.floor(this.countNum)+"."+rand);
                                },
                                complete:function(){
                                    count.text(this.countNum);
                                }
                            });
                        });
                    }, 1000);
                }
            }

            if($("figure.sig-4").length) {
                $("#info-div .fi4").html("c-4: "+_getTO("#colsec-4", -200)+" ("+window.screen.height+") ("+$("#colsec-4")[0].getBoundingClientRect().bottom+")");
                if(_getTO("#colsec-4", -200) && !scroll_s4 && !$("figure.sig-4").hasClass("anm")) {
                    scroll_s4 = true;
                    $(window).trigger("verified");
                }
            }

            if($("figure.pig-1").length) { // Premium
                if(_getTO("#partner", -1*($("#partner").innerHeight() / 1.2)) && !scroll_t2 && !$("figure.pig-1").hasClass("anm")) {
                    scroll_t2 = true;
                    $("figure.pig-1").addClass("anm");
                }
            }

            if($("figure.pig-2").length) {
                if(_getTO("#partner", -1*($("#partner").innerHeight() / 3)) && !scroll_t2 && !$("figure.pig-2").hasClass("anm")) {
                    scroll_t2 = true;
                    $("figure.pig-2").addClass("anm");
                }
            }

            if($("figure.pig-4").length) { // Appointment
                if(_getTO("#partner", -1*($("#partner").innerHeight() / 3)) && !scroll_t2 && !$("figure.pig-4").hasClass("anm")) {
                    scroll_t2 = true;
                    setTimeout(() => {
                        $("figure.pig-4").addClass("anm");
                    }, 10);
                }
            }
        }
    });
}

function _xmenu (k = "menu") {
    return k == "menu" ? ["Lead Generation", "Confirmed Meetings", "Social Media Engagement", "Premium Websites", "SaaS Product Development", "High Converting Sales Pages"]
            : k == "submenu" ? [
                "Increase sales with super hot leads delivered within 24-hours",
                "Close more deals with pre-qualified appointments",
                "Generate demand for your brand & sell more with social media",
                "Turn your website into a sales generating machine",
                "Bring your SaaS product idea to life in record time with an A-team",
                "Convert more web visitors into close deals with sales pages"
            ] : k == "icons" ? [f_m1, f_m2, f_m6, f_m3, f_m7, f_m4]
            : k == "faq" ? [[
                    {label: "Where do you generate leads from?", span: "Depending on your industry, offer, and budget we will suggest the best channel for lead generation. Kpimatrix generates hot leads from Google, Facebook, Instagram, TikTok, LinkedIn, Quora, Bing, and so many other channels."},
                    {label: 'What is a "qualified lead" for Kpimatrix?', span: "A qualified Lead is someone who has demonstrated an interest in your offer. This could be through a Lead form on a social media Ad, or an Email response indicating interest. You get enriched data on all hot leads provided by Kpimatrix."},
                    {label: "What industries or niches do you work with?", span: "We work with Brands big and small in multiple industries including but not limited to Consumer services, Financial services, Legal, Insurance, Real Estate, Health Care, Dental, Education, Events, Blockchain, and much, much more."},
                    {label: "What is the minimum number of leads I can order?", span: "Depending on your industry, offer, and budget, you can order a minimum of 10 Hot leads at any given time."},
                    {label: "Where can I access my leads after I place an order?", span: "If you already have a CRM system that you use, your leads will be sent using API. If you do not have a sales CRM, we can make a recommendation & have one set up for you where you can get access to all your leads. Alternatively, your leads can be sent in a spreadsheet file directly to your email."}
                ], [
                    {label: "Where do you generate confirmed appointments from?", span: "Depending on your industry, offer, and budget we will suggest the best channel for appointment or meeting generation. Kpimatrix generates confirmed meetings with your Ideal customers from Google, Facebook, Instagram, TikTok, LinkedIn, Quora, Bing, and so many other channels."},
                    {label: 'What is a "confirmed appointment" for Kpimatrix?', span: <>A Confirmed appointment is someone who has demonstrated high interest in your offer and wants to go on a Meeting with you to learn more.<br /><SMBlock />
                    This could be through a Calendly form linked to a social media Ad, or an Email response indicating interest in going on a meeting. You get enriched data on all confirmed appointments provided by Kpimatrix.
                    </>},
                    {label: "What industries or niches do you work with?", span: "We work with Brands big and small in multiple industries including but not limited to Consumer services, Financial services, Legal, Insurance, Real Estate, Health Care, Dental, Education, Events, Blockchain, and much, much more."},
                    {label: "What is the minimum number of Appointments I can order?", span: "Depending on your industry, offer, and budget, you can order a minimum of 10 confirmed meetings or appointments at any given time."},
                    {label: "Where can I access information about Confirmed appointments after I place an order?", span: <>If you already have a CRM system that you use, your confirmed appointments will be sent using API. If you do not have a sales CRM, we can make a recommendation & have one set up for you where you can get access to all the data about confirmed appointments.<br /><SMBlock />
                    Alternatively, your confirmed appointments can be sent in a spreadsheet file directly to your email.
                    </>}
                ], [
                    {label: "How many followers can I get per month?", span: "You can expect a social media following growth of between 200 to 1000 followers per month depending on your unique requirements."},
                    {label: "What kind of results can I expect from a social media engagement campaign?", span: "The goal of a Kpimatrix.io social media engagement campaign is to create content that attracts your ideal customers as followers or fans and implement strategies that turn them into paying customers."},
                    {label: "Do I get a social media manager that can be the face of my brand?", span: "Depending on the plan you choose to go with, Kpimatrix.io will vet and provide you with a social media manager that will single-handedly manage all video & content creation activities as the face of your brand."},
                    {label: "Can you create and edit videos for my social media channels?", span: "Yes. Kpimatrix.io will create, edit and publish engaging video content to increase your brand's visibility and conversions across all selected social media platforms."},
                    {label: "How can this make my business money?", span: "Social media engagement is not about posting random content and getting fake followers. It focuses on building real relationships with your target clients and creating offers that will turn your followers into repeat paying customers."},
                ], [
                    {label: "How long will it take for my website to be online?", span: "Depending on your requirements and the complexity of the project, a truly premium website can take anywhere from 3 weeks to 8 weeks to be 100% ready."},
                    {label: "How often can I get progress updates?", span: "Progress updates are usually done on a weekly or monthly basis depending on the agreement reached. We will assign you a success manager who will keep you up to date at all times."},
                    {label: "Will I be able to edit the content on my website after development?", span: "Yes. Kpimatrix.io works with some of the best and most powerful CMS systems to help you manage content on your own without the help of a developer."},
                    {label: "Will you make my website rank higher on Google?", span: "Yes. We will include keywords that increase google rankings & perform search engine optimization in order to increase traffic."},
                    {label: "Can I really get more clients by upgrading my website?", span: "Yes. At Kpimatrix.io, we always focus on building websites in a way that will help you attract the right customers for your business and close more deals. We do this by always including basic advertising principles such as SEO, sales copywriting, and Conversion rate optimization."}
                ], [
                    {label: "How long does it take for the MVP to be online?", span: "At Kpimatrix.io we understand the need to work fast when building SaaS products. We aim to have MVPs fully functioning and online in under 8 weeks."},
                    {label: "How many developers will work on my project? (ref dev languages, UI/UX, SEO)", span: "Depending on the size and complexity of your project, Kpimatrix.io will assign at least one expert in each critical field of development. Front-end developer, Back-end developer, database engineer, product designer, SEO product expert, product copywriter, QA engineer & database engineer."},
                    {label: "Do I get access to all my code on completion?", span: "Yes. On completion of a project, Kpimatrix.io will give you access to your entire codebase. We usually use GitHub to manage the codebase during the development process. All tools used will be handed over to the client on completion."},
                    {label: "Do I get all IP rights (Intellectual Property) for the SaaS product?", span: <>Yes! You get all IP rights to your SaaS product. Kpimatrix.io is here to help you build and grow, all IP rights of the software product belong to the client.<br /><SMBlock />
                    How often can I get progress updates?
                    Progress updates are usually done on a weekly or monthly basis depending on the agreement reached. We will assign you a success manager who will keep you up to date at all times.
                    </>}
                ], [
                    {label: "Will you make my sales page rank higher on Google?", span: "Yes. We will include keywords that increase google rankings & perform search engine optimization in order to increase traffic."},
                    {label: "How can you guarantee an increase in conversions?", span: "We do this by thoroughly researching your ICP (ideal customer profile) & creating direct response content with mouth-watering offers that get your ideal clients to convert into paying customers."},
                    {label: "How much is it going to cost?", span: "Cost depends on the unique requirements of your high-converting sales page. High-converting sales pages can cost you anywhere from $2500 or more."},
                    {label: "Do you install analytics on the sales page?", span: "Yes. Complete Google Analytics installation, tracking, and any other relevant pixels will be included on your sales page. In addition, we also include heat mapping and user behavior tracking software to help you understand how web visitors engage with your sales page."},
                    {label: "Can you connect my sales page to a CRM?", span: "Yes. We will connect your high-converting sales page to any CRM of your choice using APIs."}
                ]
            ] : null;
}

function _menu (k = "menu") {
                      // ["Lead Generation", "Confirmed Meetings", "Social Media Engagement", "Premium Websites", "SaaS Product Development", "High Converting Sales Pages"]
    return k == "menu" ? ["Lead Generation", "Premium Websites", "Confirmed Meetings", "SaaS Product Development", "Social Media Engagement", "High Converting Sales Pages"]
            : k == "submenu" ? [
                "Increase sales with super hot leads delivered within 24-hours", // 1
                "Turn your website into a sales generating machine", // 2
                "Close more deals with pre-qualified appointments", // 3
                "Bring your SaaS product idea to life in record time with an A-team", // 4
                "Generate demand for your brand & sell more with social media", // 5
                "Convert more web visitors into closed deals with sales pages" // 6
            ] : k == "icons" ? [
                f_m1, // 1
                f_m3, // 2
                f_m2, // 3
                f_m7, // 4
                f_m6, // 5
                f_m4 // 6
            ]
            : k == "faq" ? [[
                    {label: "Where do you generate leads from?", span: "Depending on your industry, offer, and budget we will suggest the best channel for lead generation. Kpimatrix generates hot leads from Google, Facebook, Instagram, TikTok, LinkedIn, Quora, Bing, and so many other channels."},
                    {label: 'What is a "qualified lead" for Kpimatrix?', span: "A qualified Lead is someone who has demonstrated an interest in your offer. This could be through a Lead form on a social media Ad, or an Email response indicating interest. You get enriched data on all hot leads provided by Kpimatrix."},
                    {label: "What industries or niches do you work with?", span: "We work with Brands big and small in multiple industries including but not limited to Consumer services, Financial services, Legal, Insurance, Real Estate, Health Care, Dental, Education, Events, Blockchain, and much, much more."},
                    {label: "What is the minimum number of leads I can order?", span: "Depending on your industry, offer, and budget, you can order a minimum of 10 Hot leads at any given time."},
                    {label: "Where can I access my leads after I place an order?", span: "If you already have a CRM system that you use, your leads will be sent using API. If you do not have a sales CRM, we can make a recommendation & have one set up for you where you can get access to all your leads. Alternatively, your leads can be sent in a spreadsheet file directly to your email."}
                ], // 1
                [
                    {label: "How long will it take for my website to be online?", span: "Depending on your requirements and the complexity of the project, a truly premium website can take anywhere from 3 weeks to 8 weeks to be 100% ready."},
                    {label: "How often can I get progress updates?", span: "Progress updates are usually done on a weekly or monthly basis depending on the agreement reached. We will assign you a success manager who will keep you up to date at all times."},
                    {label: "Will I be able to edit the content on my website after development?", span: "Yes. Kpimatrix.io works with some of the best and most powerful CMS systems to help you manage content on your own without the help of a developer."},
                    {label: "Will you make my website rank higher on Google?", span: "Yes. We will include keywords that increase google rankings & perform search engine optimization in order to increase traffic."},
                    {label: "Can I really get more clients by upgrading my website?", span: "Yes. At Kpimatrix.io, we always focus on building websites in a way that will help you attract the right customers for your business and close more deals. We do this by always including basic advertising principles such as SEO, sales copywriting, and Conversion rate optimization."}
                ], // 2
                [
                    {label: "Where do you generate confirmed appointments from?", span: "Depending on your industry, offer, and budget we will suggest the best channel for appointment or meeting generation. Kpimatrix generates confirmed meetings with your Ideal customers from Google, Facebook, Instagram, TikTok, LinkedIn, Quora, Bing, and so many other channels."},
                    {label: 'What is a "confirmed appointment" for Kpimatrix?', span: <>A Confirmed appointment is someone who has demonstrated high interest in your offer and wants to go on a Meeting with you to learn more.<br /><SMBlock />
                    This could be through a Calendly form linked to a social media Ad, or an Email response indicating interest in going on a meeting. You get enriched data on all confirmed appointments provided by Kpimatrix.
                    </>},
                    {label: "What industries or niches do you work with?", span: "We work with Brands big and small in multiple industries including but not limited to Consumer services, Financial services, Legal, Insurance, Real Estate, Health Care, Dental, Education, Events, Blockchain, and much, much more."},
                    {label: "What is the minimum number of Appointments I can order?", span: "Depending on your industry, offer, and budget, you can order a minimum of 10 confirmed meetings or appointments at any given time."},
                    {label: "Where can I access information about Confirmed appointments after I place an order?", span: <>If you already have a CRM system that you use, your confirmed appointments will be sent using API. If you do not have a sales CRM, we can make a recommendation & have one set up for you where you can get access to all the data about confirmed appointments.<br /><SMBlock />
                    Alternatively, your confirmed appointments can be sent in a spreadsheet file directly to your email.
                    </>}
                ], // 3
                [
                    {label: "How long does it take for the MVP to be online?", span: "At Kpimatrix.io we understand the need to work fast when building SaaS products. We aim to have MVPs fully functioning and online in under 8 weeks."},
                    {label: "How many developers will work on my project? (ref dev languages, UI/UX, SEO)", span: "Depending on the size and complexity of your project, Kpimatrix.io will assign at least one expert in each critical field of development. Front-end developer, Back-end developer, database engineer, product designer, SEO product expert, product copywriter, QA engineer & database engineer."},
                    {label: "Do I get access to all my code on completion?", span: "Yes. On completion of a project, Kpimatrix.io will give you access to your entire codebase. We usually use GitHub to manage the codebase during the development process. All tools used will be handed over to the client on completion."},
                    {label: "Do I get all IP rights (Intellectual Property) for the SaaS product?", span: <>Yes! You get all IP rights to your SaaS product. Kpimatrix.io is here to help you build and grow, all IP rights of the software product belong to the client.<br /><SMBlock />
                    How often can I get progress updates?
                    Progress updates are usually done on a weekly or monthly basis depending on the agreement reached. We will assign you a success manager who will keep you up to date at all times.
                    </>}
                ], // 4
                [
                    {label: "How many followers can I get per month?", span: "You can expect a social media following growth of between 200 to 1000 followers per month depending on your unique requirements."},
                    {label: "What kind of results can I expect from a social media engagement campaign?", span: "The goal of a Kpimatrix.io social media engagement campaign is to create content that attracts your ideal customers as followers or fans and implement strategies that turn them into paying customers."},
                    {label: "Do I get a social media manager that can be the face of my brand?", span: "Depending on the plan you choose to go with, Kpimatrix.io will vet and provide you with a social media manager that will single-handedly manage all video & content creation activities as the face of your brand."},
                    {label: "Can you create and edit videos for my social media channels?", span: "Yes. Kpimatrix.io will create, edit and publish engaging video content to increase your brand's visibility and conversions across all selected social media platforms."},
                    {label: "How can this make my business money?", span: "Social media engagement is not about posting random content and getting fake followers. It focuses on building real relationships with your target clients and creating offers that will turn your followers into repeat paying customers."},
                ], // 5
                [
                    {label: "Will you make my sales page rank higher on Google?", span: "Yes. We will include keywords that increase google rankings & perform search engine optimization in order to increase traffic."},
                    {label: "How can you guarantee an increase in conversions?", span: "We do this by thoroughly researching your ICP (ideal customer profile) & creating direct response content with mouth-watering offers that get your ideal clients to convert into paying customers."},
                    {label: "How much is it going to cost?", span: "Cost depends on the unique requirements of your high-converting sales page. High-converting sales pages can cost you anywhere from $2500 or more."},
                    {label: "Do you install analytics on the sales page?", span: "Yes. Complete Google Analytics installation, tracking, and any other relevant pixels will be included on your sales page. In addition, we also include heat mapping and user behavior tracking software to help you understand how web visitors engage with your sales page."},
                    {label: "Can you connect my sales page to a CRM?", span: "Yes. We will connect your high-converting sales page to any CRM of your choice using APIs."}
                ] // 6
            ] : null;
}

function MinImage (p) {
    let page = p.page;
    let ky = p.ky || 0;
    let src_0 = p.path;
    let src_1 = src_0.replace('/static/media/', "").split(".")[0];
    let ismin = src_1.match("-min");
    return (
        <img src={src_0} {...p} onLoad={(e) => {
            if(ismin) {
                src_1 = src_1.replace("-min", "");
                src_1 = require("./Assets/img/"+page+"/"+src_1+"."+((page == "leads" && ky == 3) || page == "home" ? "png" : "svg"));
                setTimeout(() => {
                    var img = new Image();
                    img.alt = p.alt || "";
                    if(p.ref) {
                        img.ref = p.ref;
                    }
                    if(p._style) {
                        img.style = p._style;
                    }
                    img.className = p.className;
                    img.src = src_1.default;
                    img.onload = function () {
                        $(e.target).replaceWith(img);
                    }
                }, 0);
            }
        }} />
    )
}

class TrustPilot extends Component {
    render () {
        let { top } = this.props;
        top = top ? top : "0px";

        return (
            <div className="flexy tpil" style={{marginLeft: "2em", justifyContent: "center", width: "235px", minWidth: "232px", alignItems: "center", position: "relative", top}}>
                <span className="mcl" style={{fontSize: "16px", marginTop: "5px"}}>Check out our <b>437</b> reviews</span>
                <span style={{marginTop: "5px"}}>
                    <img className="tp_star" src={star} alt="star" />
                    <img className="tp_star" src={star} alt="star" />
                    <img className="tp_star" src={star} alt="star" />
                    <img className="tp_star" src={star} alt="star" />
                    <img className="tp_star" src={star} alt="star" />
                </span>
                <span style={{marginTop: "5px"}}>
                    <img className="tpi dmode" src={tpi} alt="Trust pilot" />
                    <img className="tpi lmode" src={tpiAlt} alt="Trust pilot" />
                </span>
            </div>
        );
    }
}

class Header extends Component {
    constructor(props) {
        super(props);
    }
    async componentDidMount () {
        $("img.close, #app-overlay-alt, #app-overlay.active.mobile").click(function (e) {
            close_menu();
        });

        $(".sub-menu").click(function (e) {
            if(window.innerWidth > 1170) {
                if($(e.target).hasClass("app-overlay") || $(e.target).hasClass("sub-menu") || $(e.target).parents(".sub-menu").length > 0) {
                    $(".app-menu").removeClass("active");
                    $("#app-overlay, #app-overlay-alt").removeClass("active");
                    $(".ud.active").removeClass("active");
                    close_menu();
                }
            }
        });

        $("#app-overlay").click(function (e) {
            if(window.innerWidth > 1170) {
                if(!$("#app-overlay").hasClass("mobile")) {
                    if($(e.target).hasClass("app-overlay") || $(e.target).hasClass("sub-menu") || $(e.target).parents(".sub-menu").length > 0) {
                        $(".app-menu").removeClass("active");
                        $("#app-overlay, #app-overlay-alt").removeClass("active");
                        $(".ud.active").removeClass("active");
                        close_menu();
                    }
                }
            }
        });

        function close_menu () {
            $("body").removeClass("menu_ed").css("overflow", "auto");
            $(".blurry").removeClass("blurry");
            $(".app-menu.active, .ud").removeClass("active");
            $("#app-overlay, #app-overlay-alt").removeClass("active").removeClass("mobile");
        }
    }
    switchMode = (e) => {
        let mode = e.target.checked;
        if($("[data-theme]").attr("data-theme") == "dark") {
            $(".switch input").prop("checked", false);
            setCookie("theme", "light", 365);
            $("body").attr("data-theme", "light");
            $("html").attr("style", "background: #fff !important;");
        } else {
            $(".switch input").prop("checked", true);
            setCookie("theme", "dark", 365);
            $("body").attr("data-theme", "dark");
            $("html").attr("style", "background: #181c25 !important;");
        }
        $(".pzz").css("opacity", mode ? "1" : "0.4");
    }
    menuClicked = (e) => {
        let target = $(e.target);
        if(target.hasClass("ud")) {
            target = target.parent();
        }
        let _for = target.data("for");
        $("body").addClass("menu_ed");
        if($(".app-menu.for-"+_for).hasClass("active")) {
            $(".app-menu.for-"+_for).removeClass("active");
        } else {
            $(".app-menu.active:not(.for-"+_for+")").removeClass("active");
            if(_for == "all") {
                $("#app-overlay").addClass("active").addClass("mobile");
                $("body").css("overflow", "hidden");
            } else if($(".app-menu.for-"+_for).hasClass("active")) {
                $(".app-menu.for-"+_for).removeClass("active");
                $("#app-overlay:not(.mobile)").removeClass("active");
                $(".for-"+_for+".ud").removeClass("active");
            } else if(!$(".app-menu.for-"+_for).hasClass("active")) {
                $(".app-menu.for-"+_for).addClass("active");
                $("#app-overlay:not(.mobile)").addClass("active");
                $(".for-"+_for+".ud").addClass("active");
            }
            $("#app-overlay-alt").addClass("active");
            $("#root > *:not(#app-overlay, .cale-pop)").addClass("blurry");
        }
    }

    closeMenu = () => {
        $("body").removeClass("menu_ed").css("overflow", "auto");
        $(".blurry").removeClass("blurry");
        $(".app-menu.active, .ud").removeClass("active");
        $("#app-overlay, #app-overlay-alt").removeClass("active").removeClass("mobile");
    }

    page = (p = '', q = true) => {
        if(!q) {
            return '#';
        }
        return p == this.props.page ? '#' : '/'+p;
    }

    setLang = (l) => {
        setCookie("lang", l, 365);
        window.location.href = "";
    }

    render () {
        let _menu_ = _menu();
        let _submenu_ = _menu("submenu");
        return (
            <>
                <nav className="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav">
                    <div className="container px-4 px-lg-5 mcl">
                        <a href="/home" title='Kpimatrix' ref={(e) => _ref(e, "text-decoration", "none")} className="navbar-brand text-center mt-0 tfc-0" style={{fontSize: "30px", fontWeight: "100", letterSpacing: "5px"}}>
                            <figure className='mlfi'>
                                <img src={kpi_alt} alt="Kpimatrix" className="mlo dmode" style={{width: "155px", height: "46px"}} />
                                <img src={kpi} alt="Kpimatrix" className="mlo lmode" style={{width: "155px", height: "46px"}} />
                            </figure>
                        </a>
                        {/* <div className="rsec responsive" style={{position: "relative", right: "-20px"}}>
                            <label className="switch mob" htmlFor="switch_theme_0">
                                <input type="checkbox" id="switch_theme_0" aria-label="Switch" defaultChecked={_getCookie("theme") != 0 ? true : false} onChange={this.switchMode} />
                                <span className="slider round"></span>
                            </label>
                            <button className="navbar-toggler navbar-toggler-right" type="button" data-for="all" onClick={this.menuClicked}><img src={jam} /></button>
                        </div>
                        <div className="collapse navbar-collapse" id="navbarResponsive">
                            <ul className="navbar-nav ms-auto my-2 my-lg-0">
                                <li className="nav-item nav-wrapper"><span className="nav-link sl-nav" style={{top: "0px", height: "40px"}}><i className="sl-flag"><img src={usa} alt="" /></i><span className='flag-span'>&nbsp;English</span>&nbsp;<img src={ud} className="ud" alt="" />
                                    <div className="triangle"></div>
                                    <ul className="lang-menu">
                                    <li><i className="sl-flag" onClick={() => this.setLang("en")}><img src={uk} alt="" /></i>&nbsp;<span className="mci">English</span></li>
                                    <li><i className="sl-flag" onClick={() => this.setLang("es")}><img src={spain} alt="" /></i>&nbsp;<span className="mci">Spanish</span></li>
                                    <li><i className="sl-flag" onClick={() => this.setLang("de")}><img src={germany} alt="" /></i>&nbsp;<span className="mci">German</span></li>
                                    <li><i className="sl-flag" onClick={() => this.setLang("fr")}><img src={france} alt="" /></i>&nbsp;<span className="mci">French</span></li>
                                    </ul></span>
                                </li>
                                <li className="nav-item mw8">
                                    <label className="switch" htmlFor="switch_theme_1">
                                        <input type="checkbox" id="switch_theme_1" aria-label="Switch" defaultChecked={_getCookie("theme") != 0 ? true : false} onChange={this.switchMode} />
                                        <span className="slider round"></span>
                                    </label>
                                </li>
                                <li className="nav-item"><span className="nav-link" data-for="features" onClick={this.menuClicked}>Features <img src={ud} className="ud for-features" alt="" /></span></li>
                                <li className="nav-item"><a className="nav-link" href={this.page("pricing", false)} disabled title="Pricing">Pricing</a></li>
                                <li className="nav-item"><span className="nav-link" data-for="resources" onClick={this.menuClicked}>Resources <img src={ud} className="ud for-resources" alt="" /></span></li>
                                <li className="nav-item" style={{paddingLeft: "30px", position: "relative"}}><button className="btn btn-primary btn-xl swae" style={{width: '206px', height: "46px", top: "2px", fontSize: "14px"}}>Speak with an Expert<img src={air} className="air" alt="" /></button></li>
                            </ul>
                        </div> */}
                    </div>
                </nav>
                <div id="app-overlay-alt"></div>
                {/* <div id="app-overlay" className="app-overlay">
                    <div className="logclo responsive">
                        <a href="/home" title='Kpimatrix'>
                            <img src={kpi_alt} alt="Kpimatrix" className="mlo dmode" />
                            <img src={kpi} alt="Kpimatrix" className="mlo lmode" />
                        </a>
                        <img src={close} className="close" alt="" />
                    </div>
                    <div className="app-menu for-all mcl responsive">
                        <div className="sub-menu real"><b data-for="features" onClick={this.menuClicked}>Features <img src={ud} className="ud for-features" alt="" /></b></div>
                    </div>
                    <div className="app-menu for-features mcl">
                        <div className={"sub-menu"+(this.props.page == "leads" ? " active" : "")}><a href={this.page("leads")} title="Leads" className="flexx"><img src={f_m1} alt="" /><div className="flexy"><b>{_menu_[0]}</b><span>{_submenu_[0]}</span></div></a></div>
                        <div className={"sub-menu"+(this.props.page == "appointment" ? " active" : "")}><a href={this.page("appointment")} title="Appointment" className="flexx"><img src={f_m2} alt="" /><div className="flexy"><b>{_menu_[2]}</b><span>{_submenu_[2]}</span></div></a></div>
                        <div className={"sub-menu"+(this.props.page == "social" ? " active" : "")}><a href={this.page("social")} title="Social" className="flexx"><img src={f_m6} alt="" /><div className="flexy"><b>{_menu_[4]}</b><span>{_submenu_[4]}</span></div></a></div>
                        <div className={"sub-menu"+(this.props.page == "premium" ? " active" : "")}><a href={this.page("premium")} title="Premium" className="flexx"><img src={f_m3} alt="" /><div className="flexy"><b>{_menu_[1]}</b><span>{_submenu_[1]}</span></div></a></div>
                        <div className={"sub-menu"+(this.props.page == "sass" ? " active" : "")}><a href={this.page("sass")} title="Sass" className="flexx"><img src={f_m7} alt="" /><div className="flexy"><b>{_menu_[3]}</b><span>{_submenu_[3]}</span></div></a></div>
                        <div className={"sub-menu"+(this.props.page == "sales" ? " active" : "")}><a href={this.page("sales")} title="Sales" className="flexx"><img src={f_m4} alt="" /><div className="flexy"><b>{_menu_[5]}</b><span>{_submenu_[5]}</span></div></a></div>
                    </div>
                    <div className="app-menu for-all mcl responsive">
                        <div className="sub-menu real"><a href={this.page("pricing", false)} disabled title="Pricing"><b>Pricing</b></a></div>
                    </div>
                    <div className="app-menu for-all mcl responsive">
                        <div className="sub-menu real"><b data-for="resources" onClick={this.menuClicked}>Resources <img src={ud} className="ud for-resources" alt="" /></b></div>
                    </div>
                    <div className="app-menu for-resources mcl">
                        <div className="sub-menu _mn2"><a className="flexx" href={this.page("blog", false)} disabled><img src={r_m1} alt="" /><div className="flexy"><b>Blog articles</b><span>Learn more about revenue growth, website automation, advertising & so much more.</span></div></a></div>
                        <div className="sub-menu _mn2"><a className="flexx" href={this.page("casestudies", false)} disabled><img src={r_m5} alt="" /><div className="flexy"><b>Case studies</b><span>Get an insiders look into how we work & past results that we have delivered for our clients.</span></div></a></div>
                        <div className="sub-menu _mn2"><a className="flexx" href={this.page("faq")}><img src={r_m6} alt="" /><div className="flexy"><b>FAQ</b><span>Explore a compiled list of questions we get asked frequently about our awesome services.</span></div></a></div>
                    </div>
                    <div className="app-menu sw for-all mcl responsive">
                        <div className="sub-menu real"><button className="btn btn-primary btn-xl swae" style={{height: "50px", fontSize: "14px"}}>Speak with an Expert<img src={air} className="air" alt="" /></button></div>
                    </div>
                    <footer className="bg-light py-2 footer h responsive" style={{flexDirection: "column", marginTop: "1em"}}>
                        <div className="container _nopri_ px-4 px-lg-5"><div className="small text-center text-muted mcl f_24" style={{fontWeight: "400", margin: "10px 0", cursor: "pointer"}}>Currently Accepting Followers</div></div>
                        <div className="container _nopri_ px-4 px-lg-5" style={{height: "150px"}}>
                            <div className="small text-center text-muted" style={{fontWeight: "400", margin: "0px 0px 2em 0px", cursor: "pointer"}}>
                                <img src={_i} className="fip" alt="" />
                                <img src={_f} className="fip" alt="" />
                                <img src={_y} className="fip" alt="" />
                            </div>
                        </div>
                    </footer>
                </div> */}
            </>
        );
    }
}

class Footer extends Component {
    render () {
        let { page, foot, html, cta } = this.props;
        return (
            <>
                {page == "blank" ? null :
                    <section className="page-section mcl rbg rbg-dark js-scroll fade-in" id="dc" style={{marginTop: "50px", position: "relative"}}>
                        <div className="container px-2 px-lg-5" style={{zIndex: 10000, position: 'relative'}}>
                            {html}
                            <center style={{marginTop: "40px", marginBottom: "2em"}}>
                                <button className="btn btn-primary btn-xl swae">{cta}<img src={air} className="air" alt="" /></button>
                            </center>
                            <center className="flexx w100"><TrustPilot top="0px" /></center>
                        </div>
                    </section>
                }

                <section className="page-section" id="footer-0">
                    <div className="container px-4 px-lg-5 mcl" ref={(e) => {
                        _ref(e, "padding", "0px");
                        _ref(e, "max-width", "100%");
                    }}>
                        <h2 className="text-center mt-0 tfc-0 fl30" style={{marginBottom: "30px"}}>
                            <figure className='mlfi'>
                                <a href='/home' title='Kpimatrix'><img src={kpi_alt} alt="Kpimatrix" className="mlo dmode" style={{width: "155px", height: "46px"}} /></a>
                                <a href='/home' title='Kpimatrix'><img src={kpi} alt="Kpimatrix" className="mlo lmode" style={{width: "155px", height: "46px"}} /></a>
                            </figure>
                        </h2>
                        <strong className="text-center mt-0 h4 f_18" style={{fontWeight: 600, marginBottom: "5px"}}>
                            THE MOST AUTHENTIC TECH & ADVERTISING <br className="is-mobile" />COMPANY YOU’LL EVER WORK WITH
                        </strong>
                        {/* <div className={"row gx-4 col-lg-9 h fnrsp not-responsive"} style={{justifyContent: "center"}}>
                            <div className="col-lg-3 col-md-6 text-center fxlt">
                                <div className="mt-5">
                                    <h3 className="h4 mb-2 wth2">Why Kpimatrix</h3>
                                    <p className="text-muted mb-col">
                                        <a href='#' title='Our Story' disabled>Our Story</a>
                                        <a href='#' title='Our Vision' disabled>Our Vision</a>
                                        <a href='#' title='Open Positions' disabled>Open Positions</a>
                                        <a href='#' title='Investment Oppurtunities' disabled>Investment Oppurtunities</a>
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 text-center fxlt">
                                <div className="mt-5">
                                    <h3 className="h4 mb-2 wth2">Features</h3>
                                    <p className="text-muted mb-col">
                                        <a href="/leads" title='Pay Per Lead'>Pay Per Lead</a>
                                        <a href='/appointment' title='Pay Per Appointment'>Pay Per Appointment</a>
                                        <a href='/premium' title='Build An Automated Website'>Build An Automated Website</a>
                                        <a href='/sass' title='SaaS Product Development'>SaaS Product Development</a>
                                        <a href='/social' title='Social Media Engagement'>Social Media Engagement</a>
                                        <a href="/sales" title='High Converting Sales Pages'>High Converting Sales Pages</a>
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 text-center fxlt">
                                <div className="mt-5">
                                    <h3 className="h4 mb-2 wth2">Resources</h3>
                                    <p className="text-muted mb-col">
                                        <a href='#' title='Blog Articles' disabled>Blog Articles</a>
                                        <a href='#' title='Case Studies' disabled>Case Studies</a>
                                        <a href='/faq' title='FAQ'>FAQ</a>
                                        <a href='/legal' title='Legal & Docs'>Legal & Docs</a>
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 text-center fxlt">
                                <div className="mt-5">
                                    <h3 className="h4 mb-2 wth2">Offers</h3>
                                    <p className="text-muted mb-col">
                                        <a href='#' title='Affiliate programs' disabled>Affiliate programs</a>
                                        <a href='#' title='Free discovery call' disabled>Free discovery call</a>
                                        <a href='#' title='Pricing' disabled>Pricing</a>
                                    </p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </section>

                {/* <footer className="bg-light py-2 footer" id="footer">
                    <div className="container px-4 px-lg-5"><div className="small text-center text-muted tfc-1 mcl f_24" style={{fontWeight: 400, margin: "10px 0", cursor: "pointer"}}>Currently Accepting Followers</div></div>
                    <div className="container px-4 px-lg-5">
                        <div className="small text-center text-muted" style={{fontWeight: 400, margin: "20px 0", cursor: "pointer"}}>
                            <img src={_i} className="fip lmode" alt="" />
                            <img src={_f} className="fip lmode" alt="" />
                            <img src={_y} className="fip lmode" alt="" />
                            <img src={i_dark} className="fip dmode" alt="" />
                            <img src={f_dark} className="fip dmode" alt="" />
                            <img src={y_dark} className="fip dmode" alt="" />
                        </div>
                    </div>
                    <div className="container px-4 px-lg-5 arr fttt"><div className="small text-center text-muted tfc-2 mcl" style={{fontSize: "14px", fontWeight: 400, margin: "10px 0 20px", cursor: "pointer"}}>© 2022 Kpimatrix. All rights reserved</div></div>
                </footer> */}
            </>
        )
    }
}

class Masthead extends Component {
    render () {
        let {
            left_light, left_dark,
            left_light_md, left_dark_md,
            left_light_sm, left_dark_sm,
            right_light, right_dark, page,
            text, tabs, alt, cta, ellipse,
            Hero, trust } = this.props;
        let except = page == "pricing" || page == "faq" || page == "legal" || page == "story";

        return (
            <header className={"masthead"+(alt ? " alt" : "")} style={{overflow: "hidden", position: "relative"}} ref={(e) => {
                if(alt) {
                    _ref(e, "min-height", "auto");
                    // _ref(e, "padding-bottom", "100px");
                }
            }}>
                {
                    alt && text != null ? (
                        <>
                            <img src={pll} className="ple lmode" alt="" />
                            <img src={pld} className="ple dmode" alt="" />
                            <img src={ple} className="plem" alt="" />
                        </>
                    ) : null
                }

                {alt && !except ? <Hero page={page} /> : null}

                <div className="container px-4 px-lg-5 h-100" style={{marginTop: page == "home" ? "106px" : (page == "pricing" ? "5%" : "8%")}}>
                    {ellipse ? <img className='hero-eli' src={ellipse} /> : null}
                    <div className="row h-100" style={{position: 'relative'}}>
                        <div className={"col-md-"+(alt ? "12 flexc" : "6")+" hts mcl"} style={{position: "relative"}}>
                            {
                                alt && right_dark ? (
                                    <>
                                        <img src={right_light} className="coin lmode" alt="" />
                                        <img src={right_dark} className="coin dmode" alt="" />
                                    </>
                                ) : null
                            }
                            {
                                page == "home" ? <>
                                    <img src={left_dark} className="fdf dmode" alt="" ref={(e) => {
                                        if(alt) {
                                            _ref(e, "max-width", text == null ? "700px" : "900px");
                                        }
                                    }} />
                                    <img src={left_light} className="fdf lmode" alt="" ref={(e) => {
                                        if(alt) {
                                            _ref(e, "max-width", text == null ? "700px" : "900px");
                                        }
                                    }} />
                                </> : page == "_home" ? <>
                                    <h1 className='mh1'>Find Your<br /><span ref={(e) => { _ref(e, "color", "#7D97F4"); }}>Dream Clients</span><br />Faster</h1>
                                </> : page == "sales" ? <>
                                    <h1 className='mh1'>If Sales Pages <br className='is-tablet' /><br className='is-mobile' />Are <br className='is-desktop' />Horsepower</h1>
                                    <h4 className='mh2'>Kpimatrix Is <br className='is-mobile' />A Bugatti Chiron</h4>
                                </> : page == "leads" ? <>
                                    <h1 className='mh1'>If Lead Generation <br />were a painting</h1>
                                    <h4 className='mh2'>Kpimatrix is Picasso</h4>
                                </> : page == "appointment" ? <>
                                    <h1 className='mh1'>Turn Conversations <br />Into Liquid Cash</h1>
                                    <h4 className='mh2'>With <span className='not-mobile-2'>Qualified </span>Appointments</h4>
                                </> : page == "sass" ? <>
                                    <h1 className='mh1'>Make Millions By <br className='is-mobile' />Bringing <br className='is-desktop' /><br className='is-tablet' />Your SaaS <br className='is-smalls' />Product Idea <span ref={(e) => { _ref(e, "color", "#7D97F4"); }}>To <br className='is-tablet' />Life <br className='is-smalls' /><br className='is-desktop' />In Record Time</span></h1>
                                </> : page == "premium" ? <>
                                <h1 className='mh1'>Get A Jaw Dropping <br className='is-mobile' /><br className='is-tablet' />Website <br className='is-desktop' />That Converts Like <br className='is-tablet' />Crazy. <br className='is-desktop' /><span ref={(e) => { _ref(e, "color", "#7D97F4"); }}>Need A Website <br className='is-tablet' />Upgrade?</span></h1>
                                </> : page == "social" ? <>
                                <h1 className='mh1'>Turn your social <br className='is-mobile' />media <br className='is-desktop' /><br className='is-tablet' />channels into <br className='is-mobile' />a <span ref={(e) => { _ref(e, "color", "#7D97F4"); }}>sales <br className='is-desktop' /><br className='is-tablet' />generating <br className='is-mobile' />machine</span></h1>
                                </> : page == "pricing" ? <>
                                <h1 className='mh1'>Results Inspired Pricing <br /><span ref={(e) => { _ref(e, "color", "#7D97F4"); }}>100% Satisfaction Guarantee</span></h1>
                                </> : page == "faq" ? <>
                                <h1 className='mh1'>We've got answers! <br /><span ref={(e) => { _ref(e, "color", "#7D97F4"); }}>Pick a question</span></h1>
                                </> : left_light_md ? <>
                                    <img src={left_dark} className="fdf is-desktop dmode" alt="" ref={(e) => { if(alt) { _ref(e, "max-width", text == null ? "700px" : "900px"); } }} />
                                    <img src={left_light} className="fdf is-desktop lmode" alt="" ref={(e) => { if(alt) { _ref(e, "max-width", text == null ? "700px" : "900px"); } }} />
                                    <img src={left_dark_md} className="fdf is-tablet dmode" alt="" ref={(e) => { if(alt) { _ref(e, "max-width", text == null ? "700px" : "900px"); } }} />
                                    <img src={left_light_md} className="fdf is-tablet lmode" alt="" ref={(e) => { if(alt) { _ref(e, "max-width", text == null ? "700px" : "900px"); } }} />
                                    <img src={left_dark_sm} className="fdf is-mobile dmode" alt="" ref={(e) => { if(alt) { _ref(e, "max-width", text == null ? "700px" : "900px"); } }} />
                                    <img src={left_light_sm} className="fdf is-mobile lmode" alt="" ref={(e) => { if(alt) { _ref(e, "max-width", text == null ? "700px" : "900px"); } }} />
                                </> : <>
                                    <img src={left_dark} className="fdf dmode" alt="" ref={(e) => {
                                        if(alt) {
                                            _ref(e, "max-width", text == null ? "700px" : "900px");
                                        }
                                    }} />
                                    <img src={left_light} className="fdf lmode" alt="" ref={(e) => {
                                        if(alt) {
                                            _ref(e, "max-width", text == null ? "700px" : "900px");
                                        }
                                    }} />
                                </>
                            }

                            {text}
                            {trust ? <div className='trst'><TrustPilot top="14px" /></div> : null}
                            {except ? null :
                            <div className='flexx tab alt'>
                                <button className="btn btn-primary btn-xl btno swae" style={{marginTop: "2em", fontSize: '16px'}}><span className='light'></span>{cta}<img src={air} className="air" alt="" /></button>
                                <TrustPilot top="14px" />
                            </div>}
                        </div>
                        {alt ? null :
                        <div className="col-md-6 its is-bigs">
                            {page.toLowerCase() == "home" ? <Hero /> : alt ? null : <div className="hero-sect" style={{justifyContent: "center"}}>
                                {page == "sales" ? <Hero /> : page == "leads" ? <Hero /> : page == "appointment" ? <Hero /> : <>
                                    <img src={right_light} className="rock lmode" alt="" />
                                    <img src={right_dark} className="rock dmode" alt="" />
                                </>}
                            </div>}
                        </div>}
                    </div>
                    
                    {page.toLowerCase() == "home" ?
                        <div className="hero-sect-2 is-smalls">
                            <img src={mobh} className="hrr mobh" style={{zIndex: -2}} alt="" />
                            <img src={ic_face} className="hrf float" alt="" />
                            <img src={ic_love} className="hrl float alt" alt="" />
                        </div>
                    : null}
                </div>
            </header>
        )
    }
}

class Column extends Component {
    render () {
        let {
            img_light, img_dark, mobile, trust, page, html, top, _style, same, ky, swae, cta,
            Col1, Col2, Col3, Col4
        } = this.props;
        if(_style) {
            if(!_style.hasOwnProperty("position")) {
                _style.position = "relative";
            }
            if(!_style.hasOwnProperty("paddingTop")) {
                _style.paddingTop = "0px";
            }
        }
        _style = _style ? _style : {position: "relative"};
        if(top) {
            _style.paddingTop = top;
        }
        if(swae) {
            swae = {marginTop: "2em", fontSize: swae};
        } else {
            swae = {marginTop: "2em"};
        }

        function C45 () {
            if(page == "sales" && ky == "3") {
                return (
                    <span className="c45" ref={(e) => {
                        _ref(e, "color", "#fff");
                    }} style={{minWidth: "100px", textAlign: "center"}}><span className="counter" data-count="-45">0</span>%</span>
                )
            } else {
                return null;
            }
        }

        function MyImage (p) {
            if(!page) {
                return (<img src={p.path} {...p} />)
            }
            return MinImage({...p, ky, page});
        }
        
        return (
            <>
                {trust ? 
                    <section className={"page-section mcl colsec trust"+(ky ? " colsec-"+ky : "")} id={"colsec-"+ky} style={_style}>
                        <div className="container px-4 px-lg-5 h-100">
                            <div className="row h-100">
                                <div className="col-lg-6 kts nopd2 js-scroll slide-left cl-tx">
                                    {html}
                                    <div className="flexx not-responsive" style={{width: "calc(100% + 100px)", position: "relative", left: "0px"}}>
                                        <button className="btn btn-primary btn-xl btno swae" style={swae}>{cta}<img src={air} className="air" alt="" /></button>
                                        <TrustPilot top="22px" />
                                    </div>
                                </div>
                                <div className="col-lg-6 jts dcenter is-bigs js-scroll slide-right cl-im">
                                    { page == "leads" && ky == 2 ? <Col2 />
                                    : page == "leads" && ky == 4 ? <Col4 />
                                    : page == "sales" && ky == 4 ? <Col4 />
                                    : page == "appointment" && ky == 2 ? <Col2 />
                                    : page == "appointment" && ky == 4 ? <Col4 />
                                    : page == "sass" && ky == 2 ? <Col2 />
                                    : page == "sass" && ky == 4 ? <Col4 />
                                    : page == "premium" && ky == 2 ? <Col2 />
                                    : page == "social" && ky == 2 ? <Col2 />
                                    : page == "social" && ky == 4 ? <Col4 />
                                    : <>
                                    <MyImage path={img_light} className="man pr lmode" alt="" />
                                    <MyImage path={img_dark} className="man pr dmode" alt="" /></>}
                                </div>
                            </div>
                        </div>
                        <SMBlock />
                        { page == "leads" && ky == 2 ? <Col2 className="is-smalls" />
                            : page == "leads" && ky == 4 ? <Col4 className="is-smalls" />
                            : page == "sales" && ky == 4 ? <Col4 className="is-smalls" />
                            : page == "appointment" && ky == 2 ? <Col2 className="is-smalls" />
                            : page == "appointment" && ky == 4 ? <Col4 className="is-smalls" />
                            : page == "sass" && ky == 2 ? <Col2 className="is-smalls" /> 
                            : page == "sass" && ky == 4 ? <Col4 className="is-smalls" />
                            : page == "premium" && ky == 2 ? <Col2 className="is-smalls" />
                            : page == "social" && ky == 2 ? <Col2 className="is-smalls" />
                            : page == "social" && ky == 4 ? <Col4 className="is-smalls" />
                            : <figure className='container is-smalls js-scroll fade-in' style={{position: "relative"}}>
                                {same ? <>
                                    <MyImage path={img_light} className="man pr lmode is-smalls" alt="" />
                                    <MyImage path={img_dark} className="man pr dmode is-smalls" alt="" />
                                </>:<MyImage path={mobile} className="man pr alt is-smalls" alt="" />
                                }
                            </figure>
                        }
                        <SMBlock />
                        <div className='flexx tab responsive'>
                            <button className="btn btn-primary btn-xl swae">{cta}<img src={air} className="air" alt="" /></button>
                            <TrustPilot top="5px" />
                        </div>
                        <SMBlock />
                    </section> : <section className={"page-section mcl colsec"+(ky ? " colsec-"+ky : "")} id={"colsec-"+ky} style={_style}>
                        <div className="container px-4 px-lg-5 h-100">
                            <div className="row h-100 swrw cl-im">
                                <div className="col-lg-6 jts dcenter is-bigs js-scroll slide-left">
                                    <C45 />
                                    { page == "leads" && ky == 1 ? <Col1 className="crac man" />
                                    : page == "appointment" && ky == 1 ? <Col1 className="crac man" />
                                    : page == "sass" && ky == 1 ? <Col1 className="crac man" />
                                    : page == "sass" && ky == 3 ? <Col3 className="crac man" />
                                    : page == "premium" && ky == 1 ? <Col1 className="crac man" />
                                    : page == "premium" && ky == 3 ? <Col3 className="crac man" />
                                    : page == "social" && ky == 1 ? <Col1 className="crac man" />
                                    : page == "social" && ky == 3 ? <Col3 className="crac man" />
                                    : <>
                                        <MyImage path={img_light} className="crac man lmode" alt="" />
                                        <MyImage path={img_dark} className="crac man dmode" alt=""  />
                                    </>}
                                </div>
                                <div className="col-lg-6 kts nopd2 js-scroll slide-right cl-tx p" style={{paddingLeft: "calc(100% / 12)"}}>
                                    {html}
                                </div>
                            </div>
                        </div>
                        <SMBlock />
                        {page == "home" ? <>
                            <MyImage path={mobile} className="is-smalls crac man alt" alt="" />
                        </> : 
                        <figure className='container is-smalls js-scroll fade-in' style={{position: "relative"}}>
                            <C45 />
                            { page == "leads" && ky == 1 ? <Col1 className="crac man alt" />
                            : page == "appointment" && ky == 1 ? <Col1 className="crac man alt" />
                            : page == "sass" && ky == 1 ? <Col1 className="crac man alt" />
                            : page == "sass" && ky == 3 ? <Col3 className="crac man alt" />
                            : page == "premium" && ky == 1 ? <Col1 className="crac man alt" />
                            : page == "premium" && ky == 3 ? <Col3 className="crac man alt" />
                            : page == "social" && ky == 1 ? <Col1 className="crac man alt" />
                            : page == "social" && ky == 3 ? <Col3 className="crac man alt" />
                            : same ? <>
                                <MyImage path={img_light} className="crac man alt lmode" alt="" />
                                <MyImage path={img_dark} className="crac man alt dmode" alt="" />
                            </>:<MyImage path={mobile} className="crac man alt" alt="" />
                        }</figure>}
                        <SMBlock />
                    </section>
                }
            </>
        )
    }
}

class Partner extends Component {
    render () {
        let {
            one, two, three, html, page, cta,
            Part1, Part2, Part3
        } = this.props;
        return (
            <section className="page-section" id="partner">
                <div className="container px-4 px-lg-5 mcl" ref={(e) => {
                    _ref(e, "padding-left", "0px");
                    _ref(e, "padding-right", "0px");
                }} style={{position: "relative", paddingTop: "3em", maxWidth: "100vw", overflow: "hidden", left: '-10px'}}>
                    {
                        html ? html : <>
                            <h2 className="text-center mt-0 f36 mcl is-desktop" style={{fontWeight: 500, margin: "1em", width: "100%", textAlign: "center", justifyContent: "center", lineHeight: "1.7"}}>With a partner like Kpimatrix,<br />Founders & CEOs like you can:</h2>
                            <h2 className="text-center mt-0 mcl is-tablet" style={{fontSize: "36px", fontWeight: 500, margin: "1em 0", width: "100%", textAlign: "center", justifyContent: "center", lineHeight: "1.3"}}>With a partner like Kpimatrix,<br />Founders & CEOs like you can:</h2>
                            <h2 className="text-center mt-0 mcl is-mobile" style={{fontSize: "22px", fontWeight: 500, margin: "1em 0", width: "100%", textAlign: "center", justifyContent: "center", lineHeight: "1.7"}}>With a partner like Kpimatrix, Founders & CEOs like you can:</h2>
                        </>
                    }

                    <div className='col' style={{marginTop: "4em", position: "relative", display: 'flex', flexDirection: 'column'}}>
                        <img src={line} className="iline" alt="" />
                        <div className="col _col1">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-2 m2 py-4 not-responsive">
                                        <span className="no" ref={(e) => _ref(e, "color", "rgba(211, 140, 254, 1)")}>1</span>
                                    </div>
                                    <div className="col-lg-6 py-4 pml f mcl">
                                        <span className="no responsive" ref={(e) => _ref(e, "color", "rgba(211, 140, 254, 1)")}>1</span>
                                        {one["html"]}
                                        <SMBlock small={true} />
                                        {page == "premium" ? <Part1 className="responsive mi-2" /> : one["mobile_l"] ?
                                            <>
                                                <img src={one["mobile_l"]} className="lmode responsive mi-2 js-scroll fade-in" style={{margin: "0 auto"}} />
                                                <img src={one["mobile_d"]} className="dmode responsive mi-2 js-scroll fade-in" style={{margin: "0 auto"}} />
                                            </>
                                        : <img src={one["mobile"]} className="responsive mi-2 js-scroll fade-in" style={{margin: "0 auto"}} /> }
                                        <SMBlock small={true} />
                                    </div>
                                    <div className="col-md-4 dcenter not-responsive js-scroll fade-in">
                                        {page == "premium" ? <Part1 className="mi-2" /> : (<>
                                            <img src={one["light"]} style={{maxHeight: "250px"}} alt="" className='lmode' />
                                            <img src={one["dark"]} style={{maxHeight: "250px"}} alt="" className='dmode' />
                                        </>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col _col2">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-2 m2 py-4 not-responsive">
                                        <span className="no" ref={(e) => _ref(e, "color", "rgba(255, 122, 210, 1)")}>2</span>
                                    </div>
                                    <div className="col-lg-6 py-4 pml mcl">
                                        <span className="no responsive" ref={(e) => _ref(e, "color", "rgba(255, 122, 210, 1)")}>2</span>
                                        {two["html"]}
                                        <SMBlock small={true} />
                                        {page == "leads" ? <Part2 className="responsive mi-2" /> :
                                         page == "appointment" ? <Part2 className="responsive mi-2" /> :
                                         page == "_premium" ? <Part2 className="responsive mi-2" /> : two["mobile_l"] ?
                                            <>
                                                <img src={two["mobile_l"]} className="lmode responsive mi-2 js-scroll fade-in" style={{margin: "0 auto"}} />
                                                <img src={two["mobile_d"]} className="dmode responsive mi-2 js-scroll fade-in" style={{margin: "0 auto"}} />
                                            </>
                                        : <img src={two["mobile"]} className="responsive mi-2 js-scroll fade-in" style={{margin: "0 auto"}} /> }
                                        <SMBlock small={true} />
                                    </div>
                                    <div className="col-md-4 dcenter not-responsive js-scroll fade-in">
                                    {page == "leads" ? <Part2 className="mi-2" /> :
                                     page == "appointment" ? <Part2 className="mi-2" /> :
                                     page == "_premium" ? <Part2 className="mi-2" /> : (<>
                                        <img src={two["light"]} style={{maxHeight: "250px"}} alt="" className='lmode' />
                                        <img src={two["dark"]} style={{maxHeight: "250px"}} alt="" className='dmode' /></>)}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col _col3">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-2 m2 py-4 not-responsive">
                                        <span className="no" ref={(e) => _ref(e, "color", "rgba(125, 151, 244, 1)")}>3</span>
                                    </div>
                                    <div className="col-lg-6 py-4 pml mcl" ref={(e) => _ref(e, "padding-bottom", "0px")}>
                                        <span className="no responsive" ref={(e) => _ref(e, "color", "rgba(125, 151, 244, 1)")}>3</span>
                                        {three["html"]}
                                        <SMBlock small={true} />
                                        {page == "leads" ? <Part3 className="responsive mi-2" /> :
                                         page == "sales" ? <Part3 className="responsive mi-2" /> : (three["mobile_l"] ?
                                            <>
                                                <img src={three["mobile_l"]} className="lmode responsive mi-2 js-scroll fade-in" style={{margin: "0 auto"}} />
                                                <img src={three["mobile_d"]} className="dmode responsive mi-2 js-scroll fade-in" style={{margin: "0 auto"}} />
                                            </>
                                        : <img src={three["mobile"]} className="responsive mi-2 js-scroll fade-in" style={{margin: "0 auto"}} />)}
                                        <SMBlock small={true} />
                                    </div>
                                    <div className="col-md-4 dcenter not-responsive js-scroll fade-in">
                                        {page == "leads" ? <Part3 className="mi-2" /> :
                                        page == "sales" ? <Part3 className="mi-2" /> : (<>
                                        <img src={three["light"]} style={{maxHeight: "250px"}} alt="" className='lmode' />
                                        <img src={three["dark"]} style={{maxHeight: "250px"}} alt="" className='dmode' /></>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <center className="cent" style={{marginTop: "80px", marginBottom: "1em"}}>
                        <button className="btn btn-primary btn-xl swae">{cta}<img src={air} className="air" alt="" /></button>
                    </center>
                    <center className="flexx w100"><TrustPilot top="0px" /></center><br className='not-responsive' />
                </div>
                <SMBlock small={true} />
            </section>
        );
    }
}

class Testimony extends Component {
    render () {
        let { title, subtitle } = this.props;
        var tess  = [
            {c: "", r: 1, h: "Nana Ageyi", b: "Owner / Densik",                    t: <>I and my partner approached Kpimatrix to help design our cryptocurrency exchange platform. They designed <span className="dots">...</span><span className='more'>both the website and the CRM which the people in the cryptocurrency community loved at first glance. We will be moving forward with Kpimatrix in the development and marketing for sure.</span></>},
            {c: "#3cc041", r: 1, h: "Adam Repov", b: "Founder, Vogue Chain",       t: <>I would like thank you guys for the Great design! All of my team members and the clients love the design <font className='is-desktop'>which you&nbsp;</font><span className="dots">...</span><span className='more'><font className='is-tablet'>which you&nbsp;</font>have done for us! The whole design and color combinations were absolutely fantastic. Thank you for the great work that you did and I will definitely recommend you to anyone who needs amazing service.</span></>},
            {c: "", r: 1, h: "Mattew B.O", b: "DIRECTOR/CEO, Demerald Study",      t: <>Kpimatrix created my website Demeraldstudy. They perfectly designed and built an SEO-friendly website <span className="dots">...</span><span className='more'>for me. Ever since my website has been completed, I have been getting search intent clients without performing marketing. I love how optimization-friendly my website is. Many thanks, Kpimatrix.</span></>},
            {c: "", r: 1, h: "Marcus Alfonso", b: "CEO, Takemypen",                t: <>Design and content both were outstanding. Samson and his team were very helpful throughout the project. <span className="dots">...</span><span className='more'>They kept us informed about all the development processes and also integrate our feedbacks hand to hand. The overall experience working with kpimatrix was amazing. It has always been a pleasure to work with these kinds of professional and experienced people.</span></>},
            {c: "#e54799", r: 1, h: "Brian Kamande", b: "CEO / Dukaniyetu",        t: <>I had a fantastic experience. I think I got Kpimatrix as a company that understands what I really want <span className="dots">...</span><span className='more'>to achieve and really ready to work with m. It's why I've always kept in contact.</span></>},
            {c: "", r: 1, h: "Jorge Javier", b: "Owner / Iberica Business School", t: <>We got two different designs that we could pick from and I must say both designs were very good. We pick <span className="dots">...</span><span className='more'>one of the designs and our website was completed in under 10 days. The speed at which our website was completed is out of the ordinary. The branding of our website is also very outstanding. Thanks to you guys at Kpimatrix</span></>},
        ];
        return (
            <section className={"page-section testimony stopad"}>
                <Myslider id="testy-sect" blur={true} shadow={<>
                    <img src={tesbl} className="tesb lmode" alt="" />
                    <img src={tesbd} className="tesb dmode" alt="" />
                </>}>
                    {tess.map((el, index) => (
                        <div className={"slide slide-"+index+(index % 3 == 1 ? " alt" : "")} key={index}>
                            <div className="cm-box-2 alt tpb">
                                <div className="pinfo">
                                    <Avatar name={el.h.split(" ")[0]} size="50" color={el.c.length ? el.c : null} round={true} />
                                    <div className="dfl">
                                        <h4 className='mcl'>{el.h}</h4>
                                        <span className="sm">{el.b}</span>
                                    </div>
                                </div>
                                <p className="pbold f_20 mcl">
                                    <span>{el.t}</span>
                                </p>
                                {el.r > 0 ? <button className='rdm'><span className='r'>READ MORE</span><span className='l'>SHOW LESS</span></button> : null}
                            </div>
                        </div>
                    ))}
                </Myslider>
                
                <h2 className="text-center mt-0 mcl f40" style={{fontWeight: "600"}}>{title}</h2>
                <p className='text-center f22 mcl' style={{fontSize: "18px", fontWeight: 400}}>{subtitle}</p>
            </section>
        )
    }
}

class Clients extends Component {

    async componentDidMount () {
        window.scrollBack = 0;
    }

    _scrollDir = (s = 0) => {
        // let nv = !$("#pm-box-id-2").hasClass("hover");
        // clearTimeout(scrollBack);
        ++window.scrollBack;
        let isc = window.scrollBack;
        $("#pm-box-id-2").addClass("hover");
        let x = document.getElementById("pm-box-id-2").offsetLeft;
        let w = x + 180;
            w = w < 0 ? w : -180 * 10;
        if(s > 0) {
            w = x - 180;
            w = w < (-180 * 10) ? 0 : w;
        }
        // if(nv) {
        //     $("#pm-box-id-2").data("left", w);
        // }
        // $("#pm-box-id-2").removeClass("anm");
        setTimeout(() => {
            $(".pm-box-2")[0].setAttribute('style', 'left: '+(w)+'px !important');
            setTimeout(() => {
                if(isc == window.scrollBack) {
                    if($("#pm-box-id-2").hasClass("hover")) {
                        $(".pm-box-2").removeClass("hover").removeAttr("style"); // .addClass("anm")
                    }
                } else {
                    console.log("alert");
                }
            }, 1500);
        }, 5);
    }

    render () {
        return (
            <section className="page-section rbg rbg-dark" id="clients" style={{position: "relative", overflow: "hidden", paddingTop: '50px', paddingBottom: '0px'}}>
                <div className="container px-2 px-lg-5" ref={(e) => _ref(e, "max-width", "100%")} style={{position: 'relative', zIndex: '1000'}}>
                    <h2 className="text-center mt-0 mcl f36" style={{fontWeight: 600, lineHeight: 1.7}}>Join 300+ companies in over 24 countries enjoying<br className="not-responsive" /><span className="responsive">&nbsp;</span>the Kpimatrix experience</h2>
                    <div className="pm-box-2 anm js-scroll fade-in" id="pm-box-id-2" data-left="0">

                        <div className="mt-5 xrw">
                            <img src={cl_1} className="_clogo lmode" alt="client logo" />
                            <img src={cld_1} className="_clogo dmode" alt="client logo" />
                        </div>
                        <div className="mt-5 xrw">
                            <img src={cl_2} className="_clogo lmode" alt="client logo" />
                            <img src={cld_2} className="_clogo dmode" alt="client logo" />
                        </div>
                        <div className="mt-5 xrw">
                            <img src={cl_3} className="_clogo lmode" alt="client logo" />
                            <img src={cld_3} className="_clogo dmode" alt="client logo" />
                        </div>
                        <div className="mt-5 xrw">
                            <img src={cl_4} className="_clogo lmode" alt="client logo" />
                            <img src={cld_4} className="_clogo dmode" alt="client logo" />
                        </div>
                        <div className="mt-5 xrw">
                            <img src={cl_5} className="_clogo lmode" alt="client logo" />
                            <img src={cld_5} className="_clogo dmode" alt="client logo" />
                        </div>
                        <div className="mt-5 xrw">
                            <img src={cl_6} className="_clogo lmode" alt="client logo" />
                            <img src={cld_6} className="_clogo dmode" alt="client logo" />
                        </div>
                        <div className="mt-5 xrw">
                            <img src={cl_7} className="_clogo lmode" alt="client logo" />
                            <img src={cld_7} className="_clogo dmode" alt="client logo" />
                        </div>
                        <div className="mt-5 xrw">
                            <img src={cl_8} className="_clogo lmode" alt="client logo" />
                            <img src={cld_8} className="_clogo dmode" alt="client logo" />
                        </div>
                        <div className="mt-5 xrw">
                            <img src={cl_9} className="_clogo lmode" alt="client logo" />
                            <img src={cld_9} className="_clogo dmode" alt="client logo" />
                        </div>
                        <div className="mt-5 xrw">
                            <img src={cl_10} className="_clogo lmode" alt="client logo" />
                            <img src={cld_10} className="_clogo dmode" alt="client logo" />
                        </div>
                        <div className="mt-5 xrw">
                            <img src={cl_11} className="_clogo lmode" alt="client logo" />
                            <img src={cld_11} className="_clogo dmode" alt="client logo" />
                        </div>
                        
                        <div className="mt-5 xrw">
                            <img src={cl_1} className="_clogo lmode" alt="client logo" />
                            <img src={cld_1} className="_clogo dmode" alt="client logo" />
                        </div>
                        <div className="mt-5 xrw">
                            <img src={cl_2} className="_clogo lmode" alt="client logo" />
                            <img src={cld_2} className="_clogo dmode" alt="client logo" />
                        </div>
                        <div className="mt-5 xrw">
                            <img src={cl_3} className="_clogo lmode" alt="client logo" />
                            <img src={cld_3} className="_clogo dmode" alt="client logo" />
                        </div>
                        <div className="mt-5 xrw">
                            <img src={cl_4} className="_clogo lmode" alt="client logo" />
                            <img src={cld_4} className="_clogo dmode" alt="client logo" />
                        </div>
                        <div className="mt-5 xrw">
                            <img src={cl_5} className="_clogo lmode" alt="client logo" />
                            <img src={cld_5} className="_clogo dmode" alt="client logo" />
                        </div>
                        <div className="mt-5 xrw">
                            <img src={cl_6} className="_clogo lmode" alt="client logo" />
                            <img src={cld_6} className="_clogo dmode" alt="client logo" />
                        </div>
                        <div className="mt-5 xrw">
                            <img src={cl_7} className="_clogo lmode" alt="client logo" />
                            <img src={cld_7} className="_clogo dmode" alt="client logo" />
                        </div>
                        <div className="mt-5 xrw">
                            <img src={cl_8} className="_clogo lmode" alt="client logo" />
                            <img src={cld_8} className="_clogo dmode" alt="client logo" />
                        </div>
                        <div className="mt-5 xrw">
                            <img src={cl_9} className="_clogo lmode" alt="client logo" />
                            <img src={cld_9} className="_clogo dmode" alt="client logo" />
                        </div>
                        <div className="mt-5 xrw">
                            <img src={cl_10} className="_clogo lmode" alt="client logo" />
                            <img src={cld_10} className="_clogo dmode" alt="client logo" />
                        </div>
                        <div className="mt-5 xrw">
                            <img src={cl_11} className="_clogo lmode" alt="client logo" />
                            <img src={cld_11} className="_clogo dmode" alt="client logo" />
                        </div>

                    </div>
                    <div className='slick-track slick-slider' style={{zIndex: 1000}}>
                        <span className="slick-prev" onClick={() => this._scrollDir(0)}></span>
                        <span className="slick-next" onClick={() => this._scrollDir(1)}></span>
                    </div>
                </div>

            </section>
        )
    }
}

class Tabble extends Component {
    render () {
        let { title, subtitle, rows, small, cta } = this.props;
        return (
            <section className="page-section clients rbg-dark" id="tabb" style={{position: "relative", overflow: "hidden"}}>
                <img src={monitor} className="monitor dmode" alt="" />
                <img src={tup} className="tup not-responsive dmode" alt="" />
                <img src={monitor2} className="monitor lmode" alt="" />
                <img src={tup2} className="tup not-responsive lmode" alt="" />

                {/* ref={(e) => _ref(e, "max-width", "100%")} */}
                <div className="container px-2 px-lg-5" style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", position: "relative"}}>
                    <span className='hmd' style={{fontSize: "20px", color: "#7D97F4", width: "100%", textAlign: "center", display: "flex", textAlign: "center", justifyContent: "center", marginBottom: "10px"}}>How much does it cost?</span>
                    <h2 className="text-center mt-0 mcl f40" style={{fontWeight: 500, lineHeight: 1.7, marginBottom: "20px"}}>{title}</h2>
                    <p className='text-center f22 mcl' style={{fontSize: "18px", fontWeight: 400}}>{subtitle}</p>

                    <table className='table cs mcl' style={{marginTop: "40px"}}>
                        {rows.map((el, index) => (
                            <>{el}</>
                        ))}
                    </table>
                    
                    <p className='text-center f22 fsm mcl' style={{fontSize: "14px", marginTop: "10px", fontWeight: 400}}>{small}</p>
                    
                    <center className="cent" style={{marginTop: "40px", marginBottom: "1.1em", fontSize: '20px', fontWeight: 400}}>
                        <button className="btn btn-primary btn-xl swae">{cta}<img src={air} className="air" alt="" /></button>
                    </center>
                    <center className="flexx w100"><TrustPilot top="0px" /></center>
                </div>
            </section>
        )
    }
}

class Accordion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accordion: 0
        }
    }
    render () {
        let { accordion } = this.state;
        let { list, page } = this.props;
        return (
            <div className='row gx-2 gx-lg-5 accordion'>
                {
                    list.map((ac, ak) => {
                        return (
                            <div className={'col-lg-12 accr'+(accordion == ak ? ' active' : '')} key={'acol-'+ak}>
                                <label onClick={() => this.setState({accordion: accordion == ak ? -1 : ak})}>
                                    <span>{ac.label}</span>
                                    {page == 'pricing' ? <img src={accordion == ak ? minus : plus} className="lac" alt='' style={{width: '42px', height: '42px'}} /> : <>
                                        <svg className='dmode' width="14" height="8" viewBox="0 0 14 8" fill="none" style={{transform: accordion == ak ? 'rotate(180deg)' : 'unset'}}><path d="M1 1L7 7L13 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
                                        <svg className='lmode' width="14" height="8" viewBox="0 0 14 8" fill="none" style={{transform: accordion == ak ? 'rotate(180deg)' : 'unset'}}><path d="M1 1L7 7L13 1" stroke="#1D2230" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
                                    </>}
                                </label>
                                <span className={'acs'+(accordion == ak ? ' active' : ' hide')}>{ac.span}</span>
                                {page == 'pricing' ? null : <img src={lac} className="lac" alt='' />}
                            </div>
                        );
                    })
                }
            </div>
        )
    }
}

function Block (props) {
    return (
        <div className={'kblock'+(props.small ? " sm" : "")}>
            &nbsp;
        </div>
    )
}

function SMBlock (props) {
    return (
        <div className={'kblock-sm'+(props.small ? " sm" : "")}>
            &nbsp;
        </div>
    )
}

function _dvar () {
    let fps = {fontWeight: 600, lineHeight: 1.7};
    let fpt = {marginTop: "1em", fontWeight: 400, lineHeight: 1.65};
    let _brs = (<><br className="responsive" /><span className="not-responsive nopad">&nbsp;</span></>);
    let _tln_ = (x = 1) => {
        return (<tr className={'_tln_'+(x == 0 ? ' f' : '')}><td colSpan="5"></td></tr>);
    }
    return {fps, fpt, _brs, _tln_};
}

export {log, _ref, _space, _space2, _docReady, MinImage, Clients, TrustPilot, Header, Footer, Masthead, Column, Partner, Tabble, Testimony, Accordion, Block, SMBlock, _dvar, _menu }