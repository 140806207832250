import $ from 'jquery';
import React, { Component, Suspense, useRef, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { _docReady } from '../../utils';

import "../../Assets/css/styles.css";
import "../../Assets/css/custom.scss";
import arr from "../../Assets/img/arr.svg";
import arr2 from "../../Assets/img/arr-alt.svg";


const Myslider = (props) => {
    let id = "#"+props.id;
    let blur = props.blur || false;
    useEffect(() => {

        let scroll_start = false;
        let scrolldirexn = "";
        let altid = props.id+"-alt";
        let alt_id = "#"+altid;
        let confirm = null;
        
        _resetX();

        function _resetX () {
            let slides = $(id+" .myslider.ms > .slide");
            slides.each((indx, slide) => {
                if(blur) {
                    if(indx == 0) {
                        if($(slide).find(".cm-box-2.tpb").hasClass("r")) {
                            $(slide).find(".cm-box-2.tpb .rdm").click();
                            console.log("-more");
                        }
                    }
                    setTimeout(() => {
                        if($(".cm-box-2.tpb.r").length) {
                            if($(".cm-box-2.tpb.r")[0].getBoundingClientRect().x > $(window).innerWidth()) {
                                $(".cm-box-2.tpb.r .rdm").click();
                                console.log("-more");
                            }
                        }
                    }, 600);
                }
                slide.style.transform = `translateX(${(indx -1) * 100}%)`;
            });
            document.getElementById(altid).scrollLeft = 50;
        }

        $(id+" .btn-next").click(function () {
            $(id).removeClass("pd");
            var $me = $(id+" .myslider.ms > .slide:first-child");
            $me.parent().append($me);
            console.log("next");
            _resetX();
        });

        $(id+" .btn-prev").click(function () {
            if($(id+" .myslider.ms > .slide:eq(1)").hasClass("alt")) {
                $(id).addClass("pd");
                console.log("no way");
                document.getElementById(altid).scrollLeft = 50;
                if(blur) {
                    setTimeout(() => {
                        if($(".cm-box-2.tpb.r").length) {
                            if($(".cm-box-2.tpb.r")[0].getBoundingClientRect().x > $(window).innerWidth()) {
                                $(".cm-box-2.tpb.r .rdm").click();
                            }
                        }
                    }, 600);
                }
            } else {
                var $me = $(id+" .myslider.ms > .slide:last-child");
                $me.parent().prepend($me);
                console.log("prev");
                _resetX();
            }
        });

        // $(alt_id+".scroll").on("scroll", (e) => {
        //     if(scroll_start) {
        //         // console.log("not coming through", "====");
        //         // scrolldirexn = "";
        //         clearTimeout(confirm);
        //     }
        //     let xrect_0 = e.target.scrollLeft;
        //     scroll_start = true;
        //     setTimeout(() => {
        //         let xrect_1 = e.target.scrollLeft;
        //         let diff    = e.target.scrollWidth - $(alt_id).innerWidth();
        //         scroll_listener([xrect_0, xrect_1], diff);
        //     }, 150);
        //     confirm = setTimeout(() => {
        //         let diff    = e.target.scrollWidth - $(alt_id).innerWidth();
        //         let sl = document.getElementById(altid).scrollLeft;
        //         if(diff == sl || diff == 0 || sl == 0) {
        //             scrolldirexn = "";
        //             document.getElementById(altid).scrollLeft = 50;
        //             // console.log("coming through", diff, sl, "====");
        //         }
        //     }, 500);
        // });

        function scroll_listener (x, y) {
            if(scrolldirexn.length == 0) {
                if(x[1] > x[0]) {
                    scrolldirexn = "right";
                    $(id+" .btn-next").click();
                }
                if(x[0] > x[1]) {
                    scrolldirexn = "left";
                    $(id+" .btn-prev").click();
                }
            } else {
                if(x[0] == x[1]) {
                    scrolldirexn = "";
                }
            }
        }

    });
    return (
        <>
            <div className='my-slider mr scroll' id={props.id+"-alt"}>
                <div className="myslider mr"></div>
            </div>
            <div className={'my-slider pd'} id={props.id}>
                <div className="myslider ms">
                    {props.children ? props.children : <>
                    <div className="slide slide-0">
                        <img src="https://source.unsplash.com/random?landscape,mountain" alt="" />
                    </div>
                    <div className="slide slide-2 alt">
                        <img src="https://source.unsplash.com/random?landscape,cars" alt="" />
                    </div>
                    <div className="slide slide-3">
                        <img src="https://source.unsplash.com/random?landscape,night" alt="" />
                    </div>
                    <div className="slide slide-4">
                        <img src="https://source.unsplash.com/random?landscape,city" alt="" />
                    </div>
                    <div className="slide slide-5">
                        <img src="https://source.unsplash.com/random?landscape,mountain" alt="" />
                    </div>
                    <div className="slide slide-6">
                        <img src="https://source.unsplash.com/random?landscape,cars" alt="" />
                    </div>
                    <div className="slide slide-7">
                        <img src="https://source.unsplash.com/random?landscape,night" alt="" />
                    </div>
                    <div className="slide slide-8">
                        <img src="https://source.unsplash.com/random?landscape,city" alt="" />
                    </div></>}
                </div>
                {props.shadow ? props.shadow : null}
                <button className="btn btn-next" aria-label="Next">{props.id == "media-sect" ? <img src={arr} className="" alt="" /> : <><img src={arr} className="dmode" alt="" /><img src={arr2} className="lmode" alt="" /></>}</button>
                <button className="btn btn-prev" aria-label="Prev">{props.id == "media-sect" ? <img src={arr} className="" alt="" /> : <><img src={arr} className="dmode" alt="" /><img src={arr2} className="lmode" alt="" /></>}</button>
            </div>
        </>
    );
}

export default withRouter(Myslider);