import $ from 'jquery';
import React, { Component, Suspense, useRef, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { _ref, _space } from '../../utils';

import "../../Assets/css/styles.css";
import "../../Assets/css/slider.css";
import "../../Assets/css/custom.scss";
import "../../Assets/css/flag.scss";
import "../../Assets/css/a.css";

// import fmona from '../../Assets/img/leads/monalisa/monalisa-min.png';
import mona from '../../Assets/img/leads/monalisa/monalisa.webp';
import leye from '../../Assets/img/leads/monalisa/leye.png';
import _eye from '../../Assets/img/leads/monalisa/_eye.png';
import eyo from '../../Assets/img/leads/monalisa/eyo.png';
import eyi from '../../Assets/img/leads/monalisa/eyi.png';
import eye from '../../Assets/img/leads/monalisa/eye.png';
import ed from '../../Assets/img/leads/monalisa/ed.png';
import bo from '../../Assets/img/leads/monalisa/bo.png';

import _eyo from '../../Assets/img/leads/monalisa/_eyo.png';
import _eyi from '../../Assets/img/leads/monalisa/_eyi.png';
import eye_a from '../../Assets/img/leads/monalisa/eye-alt.png';
import eyi_a from '../../Assets/img/leads/monalisa/_eyi-alt.png';

import blo from '../../Assets/img/leads/monalisa/blo.png';
import blo2 from '../../Assets/img/leads/monalisa/blo2.png';
import eli from '../../Assets/img/leads/monalisa/eli.svg';
import elipses from '../../Assets/img/leads/monalisa/elipses.svg';
import rectb from '../../Assets/img/leads/monalisa/rec-b.svg';
import rectr from '../../Assets/img/leads/monalisa/rec-r.svg';
import rsq from '../../Assets/img/leads/monalisa/rsq.svg';
import trg from '../../Assets/img/leads/monalisa/trg.svg';
import triy from '../../Assets/img/leads/monalisa/try.svg';
import vbs from '../../Assets/img/leads/monalisa/vbs.svg';
import xx from '../../Assets/img/leads/monalisa/x.svg';

class Monalisa extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    async componentDidMount () {}

    blinkEyes = () => {
        let remCls = null;
        $(".eye-case").css({opacity: 1});
        setTimeout(() => {
            $("._eli").addClass("l");
            // $("._eli, .rectb, .rectr").addClass("l");
        }, 1000);
        setInterval(() => {
            clearTimeout(remCls);
            $(".eye-case.r").addClass("blk");
            remCls = setTimeout(() => {
                $(".eye-case.r").removeClass("blk");
            }, 3000);
        }, 6000);
    }

    render () {
        return (
            <div className="hero-sec-fig">
                <img src={trg} alt='' className='trg spin' />
                <img src={rsq} alt='' className='rsq spin' />
                <img src={blo} alt='' className='blo _blink' />
                <img src={blo2} alt='' className='blo2' />
                <img src={vbs} alt='' className='vbs spin' />
                <img src={triy} alt='' className='triy spin_alt' />
                <img src={xx} alt='' className='xx' />
                <img src={eli} alt='' className='_eli' />
                <img src={elipses} alt='' className='elipses' />
                <figure style={{position: "relative", zIndex: 1}}>
                    <div style={{position: "relative"}}>
                        {/* <img src={fmona} className="fmona" alt="" style={{position: "absolute", zIndex: -1}} onLoad={() => {
                            setTimeout(() => {
                                $(".eye-case").css({opacity: 1});
                            }, 0);
                        }} /> */}
                        <img src={mona} className="mona" alt="" onLoad={this.blinkEyes} />
                    </div>
                    <div className='eye-case l not-smalls' style={{opacity: 0}}>
                        <img src={leye} className='eye l' alt="" />
                    </div>
                    <div className='eye-case r alt is-smalls' style={{opacity: 0}}>
                        <img src={bo} className='eye b' alt="" />
                        <img src={_eyo} className='eye o' alt="" style={{opacity: 0.7}} />
                        <img src={_eyi} className='eye i' alt="" />
                        <img src={eye_a} className='eye e li' alt="" />
                        <img src={eyi_a} className='eye i-alt li' alt="" />
                    </div>
                    <div className='eye-case r' style={{opacity: 0}}>
                        <img src={bo} className='eye b' alt="" />
                        <img src={eyo} className='eye o' alt="" />
                        <img src={eyi} className='eye i' alt="" />
                        <img src={eye} className='eye e' alt="" />
                        <img src={eyo} className='eye o-alt' alt="" />
                        <img src={eyi} className='eye i-alt' alt="" />
                        <img src={ed} className='eye d' alt="" />
                    </div>
                    <img src={rectb} className="rectb l" alt="" />
                    <img src={rectr} className="rectr l" alt="" />
                </figure>
            </div>
        );
    }
}

export default withRouter(Monalisa);