import $ from 'jquery';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { log, _ref, _space, _docReady, Header, Footer, TrustPilot, Masthead, Column, Partner, Tabble, Accordion, Clients, Testimony, Block, SMBlock } from '../../utils';
import Container from './Container';
import Myslider from './Slider';

// PAGE
import pbgl from "../../Assets/img/pbg-light.png";
import pbgd from "../../Assets/img/pbg-dark.png";
import elli from "../../Assets/img/elli.png";
import linsv from "../../Assets/img/line.svg";
import linls from "../../Assets/img/line-light.svg";
import linsl from "../../Assets/img/linsd.svg";
import linsd from "../../Assets/img/line-d.svg";
import arr from "../../Assets/img/arr.svg";
import play from "../../Assets/img/play.png";
import tsli1 from "../../Assets/img/tsli1.png";
import tsli2 from "../../Assets/img/tsli2.png";
import tsli3 from "../../Assets/img/tsli3.png";
import i1 from "../../Assets/img/i1.png";
import i2 from "../../Assets/img/i2.png";
import i3 from "../../Assets/img/i3.png";
import i4 from "../../Assets/img/i4.png";
import i5 from "../../Assets/img/i5.png";
import i6 from "../../Assets/img/i6.png";
import i_alt1 from "../../Assets/img/i1-alt.png";
import i_alt2 from "../../Assets/img/i2-alt.png";
import i_alt3 from "../../Assets/img/i3-alt.png";
import i_alt4 from "../../Assets/img/i4-alt.png";
import i_alt5 from "../../Assets/img/i5-alt.png";
import i_alt6 from "../../Assets/img/i6-alt.png";
import pl1 from "../../Assets/img/pl1.png";
import pl2 from "../../Assets/img/pl2.png";
import hiw from "../../Assets/img/hiw.png";

class Page extends Component {
    constructor(props) {
        super(props);
    }

    render () {
        let { page, assets, data } = this.props;
        let _pageData = (_k) => {
            return data[_k] || null;
        };
        let alt = ["sass", "premium", "social"].indexOf(page) > -1;
        var timgs = [tsli3, tsli1, tsli2, tsli3, tsli1, tsli2, tsli3, tsli1, tsli2];
        let span = "From experience, we know it is really difficult to find that one Marketing agency that can consistently deliver results and grow with you, But hey, we are it. We believe in what we do so much so that we're 100% open to giving you a test run of our service for free.";
        let accrs = _pageData('faq') || [
            {label: "Close deals while you sleep?", span},
            {label: "Diam nulla congue viverra tellus non auctor?", span},
            {label: "Egestas ac, semper faucibus odio tellus faucibus?", span},
            {label: "Laoreet nisl in felis aliquet auctor vitae?", span},
            {label: "Tellus sodales scelerisque sit amet, feugiat?", span}
        ];

        return (
            <Container page={page}>
                <Masthead
                    alt={alt}
                    page={page}
                    Hero={assets.Hero}
                    cta={_pageData('cta')}
                    left_dark={assets.sdf2}
                    left_light={assets.sdf}
                    left_dark_md={assets.md_sdf2}
                    left_light_md={assets.md_sdf}
                    left_dark_sm={assets.sm_sdf2}
                    left_light_sm={assets.sm_sdf}
                    right_dark={assets.rock_dark}
                    right_light={assets.rock_light}
                    text={_pageData('mastext')}
                />

                {page == "leads" ? <Block /> : null}

                <Column
                    ky={'1'}
                    page={page}
                    Col1={assets.Col1}
                    _style={_pageData('style_1')}
                    img_light={assets.s1_light}
                    img_dark={assets.s1_dark}
                    mobile={assets.s1_dark}
                    html={_pageData('col_1')}
                />

                <Block />

                <Column
                    ky={'2'}
                    page={page}
                    trust={true}
                    Col2={assets.Col2}
                    cta={_pageData('col_cta')}
                    _style={_pageData('style_2')}
                    img_light={assets.s2_light}
                    img_dark={assets.s2_dark}
                    mobile={assets.s2_dark}
                    html={_pageData('col_2')}
                />

                <Block />

                <section className="page-section js-scroll fade-in rbg rbg-dark" id="stats" style={{marginTop: "1.5em", overflow: 'hidden'}}>
                    <div className="container px-4 px-lg-5 mcl pad0" ref={(e) => {
                        _ref(e, "max-width", "100%");
                    }} style={{overflow: "hidden", position: "relative", zIndex: "1000000"}}>
                        <h2 className="text-center mt-0 f40" style={{fontWeight: 500, lineHeight: "1.7", padding: "0 10px"}}>How It Works</h2>
                        <br /><br className='not-responsive' />
                        <div className="row gx-4 col-lg-12" style={{justifyContent: "center"}}>
                            <div className="col-lg-2 col-md-6 text-center fxlt2 cw1">
                                <div className='ellc'>
                                    <img src={elli} className="elli" alt="" /><span className='nox' ref={(e) => _ref(e, "color", "#D38CFE")}>01</span><span className='lintr'><img src={linsv} className="dmode" alt="" /><img src={linls} className="lmode" alt="" /></span>
                                    <span className='lintd dmode is-mobile'><img src={linsd} alt="" /></span>
                                    <span className='lintd lmode is-mobile'><img src={linsl} alt="" /></span>
                                </div>
                                <div className="mt-5">
                                    <h3 className="h4 bas_ mb-2 wth-lite">{_pageData('hiw_1')[0]}</h3>
                                    <p className="text-muted mb-0 f400">{_pageData('hiw_1')[1]}</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 text-center fxlt2 cw2">
                                <div className='ellc'>
                                    <span className='lintl'><img src={linsv} className="dmode" alt="" /><img src={linls} className="lmode" alt="" /></span><img src={elli} className="elli" alt="" /><span className='nox' ref={(e) => _ref(e, "color", "#D38CFE")}>02</span><span className='lintr is-desktop'><img src={linsv} className="dmode" alt="" /><img src={linls} className="lmode" alt="" /></span>
                                    <span className='lintd dmode is-mobile'><img src={linsd} alt="" /></span>
                                    <span className='lintd lmode is-mobile'><img src={linsl} alt="" /></span>
                                </div>
                                <div className="mt-5">
                                    <h3 className="h4 mb-2 wth-lite">{_pageData('hiw_2')[0]}</h3>
                                    <p className="text-muted mb-0 f400">{_pageData('hiw_2')[1]}</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 text-center fxlt2">
                                <div className='ellc'>
                                    <span className='lintl is-desktop'><img src={linsv} className="dmode" alt="" /><img src={linls} className="lmode" alt="" /></span><img src={elli} className="elli" alt="" /><span className='nox' ref={(e) => _ref(e, "color", "#D38CFE")}>03</span><span className='lintr'><img src={linsv} className="dmode" alt="" /><img src={linls} className="lmode" alt="" /></span>
                                    <span className='lintd dmode is-mobile'><img src={linsd} alt="" /></span>
                                    <span className='lintd lmode is-mobile'><img src={linsl} alt="" /></span>
                                </div>
                                <div className="mt-5">
                                    <h3 className="h4 mb-2 wth-lite">{_pageData('hiw_3')[0]}</h3>
                                    <p className="text-muted mb-0 f400">{_pageData('hiw_3')[1]}</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 text-center fxlt2">
                                <div className='ellc'><span className='lintl'><img src={linsv} className="dmode" alt="" /><img src={linls} className="lmode" alt="" /></span><img src={elli} className="elli" alt="" /><span className='nox' ref={(e) => _ref(e, "color", "#D38CFE")}>04</span></div>
                                <div className="mt-5 l">
                                    <h3 className="h4 mb-2 wth-lite">{_pageData('hiw_4')[0]}</h3>
                                    <p className="text-muted mb-0 f400">{_pageData('hiw_4')[1]}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <img src={pl1} className="pl1" alt="" />
                    <img src={hiw} className="pl2" alt="" />
                </section>

                <Block />
                <SMBlock small={true} />

                <Column
                    ky={'3'}
                    page={page}
                    Col3={assets.Col3}
                    _style={_pageData('style_3')}
                    img_light={assets.s3_light}
                    img_dark={assets.s3_dark}
                    mobile={assets.s3_dark}
                    html={_pageData('col_3')}
                />

                <Block />
                <Block />

                <Column
                    ky={'4'}
                    page={page}
                    trust={true}
                    Col4={assets.Col4}
                    cta={_pageData('col_cta')}
                    _style={_pageData('style_4')}
                    img_light={assets.s4_light}
                    img_dark={assets.s4_dark}
                    mobile={assets.s4_dark}
                    html={_pageData('col_4')}
                />

                <Block />
                
                <Clients />

                {/* <section className="page-section clients rbg-dark" id="wps" style={{position: "relative", overflow: "hidden", height: "485px"}}>    
                    <img src={pbgl} className="pbg lmode" alt="" />
                    <img src={pbgd} className="pbg dmode" alt="" />
                    <Myslider id="media-sect">
                        {timgs.map((el, index) => (
                            <div className={"slide slide-"+index+(index % 3 == 1 ? " alt" : "")} key={index}>
                                <span className="wistia_embed wistia_async_boo6jgtiux popover=true popoverAnimateThumbnail=true" style={{display: "inline-block", height: "92%", position: "relative", width: "92%", border: "4px solid #728adf"}}>&nbsp;</span>
                            </div>
                        ))}
                    </Myslider>
                    <h2 className="text-center mt-0 mcl f40" style={{position: "absolute", top: "68px", width: "100%", fontWeight: 500, lineHeight: 1.7, zIndex: 1000}}>What People Say About Us</h2>
                </section> */}

                <Block />

                <Partner
                    html={
                        <h2 className="text-center mt-0 f40 ghs mcl" style={{fontWeight: 500, margin: "1em", lineHeight: "56px"}}>{_pageData("with")}</h2>
                    }
                    page={page}
                    Part1={assets.Part1}
                    Part2={assets.Part2}
                    Part3={assets.Part3}
                    one={{
                        html: _pageData('partner_1'),
                        dark: assets.t1_dark,
                        light: assets.t1_light,
                        mobile_l: assets.t1_light,
                        mobile_d: assets.t1_dark
                    }}
                    two={{
                        html: _pageData('partner_2'),
                        dark: assets.t2_dark,
                        light: assets.t2_light,
                        mobile_l: assets.t2_light,
                        mobile_d: assets.t2_dark
                    }}
                    three={{
                        html: _pageData('partner_3'),
                        dark: assets.t3_dark,
                        light: assets.t3_light,
                        mobile_l: assets.t3_light,
                        mobile_d: assets.t3_dark
                    }}
                    cta={_pageData('col_cta')}
                />

                {/* <Block small={true} /> */}

                <Tabble
                    title={_pageData('t_title')}
                    subtitle={_pageData('t_subtitle')}
                    rows={_pageData('t_rows')}
                    small={_pageData('t_small')}
                    cta={_pageData('col_cta')}
                />

                <Block />
                <SMBlock small={true} />

                <Testimony
                    title={<>Why CEO's and Founders <br className='is-tablet' />Trust Kpimatrix</>}
                    subtitle={<>The most authentic tech & advertising company <br className="is-mobile" />you'll ever work with</>}
                />

                <Block />

                <section className="page-section js-scroll fade-in" id="services" style={{position: "relative"}}>
                    <img src={i1} className="i1 spin is-desktop is-tablet" alt="" />
                    <img src={i2} className="i2 spin is-desktop is-tablet" alt="" />
                    <img src={i3} className="i3 spin_alt is-desktop is-tablet" alt="" />
                    <img src={i4} className="i4 spin_alt is-desktop is-tablet" alt="" />
                    <img src={i5} className="i5 spin is-desktop is-tablet" alt="" />
                    <img src={i6} className="i6 spin is-desktop is-tablet" alt="" />
                    
                    <img src={i_alt1} className="i1 spin_alt is-mobile" alt="" />
                    <img src={i_alt2} className="i2 spin_alt is-mobile" alt="" />
                    <img src={i_alt3} className="i3 spin is-mobile" alt="" />
                    <img src={i_alt4} className="i4 spin is-mobile" alt="" />
                    <img src={i_alt5} className="i5 spin is-mobile" alt="" />
                    <img src={i_alt6} className="i6 spin is-mobile" alt="" />

                    <div className="container px-4 px-lg-5 mcl" style={{position: "relative", paddingTop: "0px", maxWidth: "100vw"}}>
                        <h2 className="text-center mt-0 pt1 ghs f40" style={{lineHeight: "1.4"}}>{_pageData("service")}</h2>
                        {page == "sales" ? <p className="text-center plink f22" ref={(e) => _ref(e, "color", "#7D97F4")} style={{marginTop: "30px"}}>Here are a few questions that could help.</p> : null}
                        <br /><br className="not-responsive" />
                        <Accordion list={accrs} page={page} />
                    </div>
                </section>

                <Footer
                    cta={_pageData('cta')}
                    html={_pageData('footer')}
                />
            </Container>
        );
    }
}

export default withRouter(Page);