import $ from 'jquery';
import React, { Component, Suspense, useRef, useEffect, useState } from 'react';

// Leads
import bl from '../../Assets/img/leads/col-2/e.png';
import fl from '../../Assets/img/leads/col-2/fl.png';
import he from '../../Assets/img/leads/col-2/h.png';
import md from '../../Assets/img/leads/col-2/mde.png';
import mdm from '../../Assets/img/leads/col-2/md.webp';
import p1 from '../../Assets/img/leads/col-2/p1.png';
import ph from '../../Assets/img/leads/col-2/ph.png';
import tb from '../../Assets/img/leads/col-2/tab.png';
import pl from '../../Assets/img/leads/col-2/pl.svg';
import beli from '../../Assets/img/leads/col-4/beli.webp';
import seli from '../../Assets/img/leads/col-4/seli.png';
import flw from '../../Assets/img/leads/col-4/flw.png';
import lm1 from '../../Assets/img/leads/col-4/lm1.png';
import lm2 from '../../Assets/img/leads/col-4/lm2.png';
import shu from '../../Assets/img/leads/col-4/shu.png';
import lye from '../../Assets/img/leads/fig-3/leye.svg';
import pho from '../../Assets/img/leads/fig-3/pho.svg';
import rey from '../../Assets/img/leads/fig-3/rey.svg';
import rye from '../../Assets/img/leads/fig-3/reye.svg';
import rwy from '../../Assets/img/leads/fig-3/rwy.svg';

import lap from '../../Assets/img/leads/fig-2/lap.svg';
import _env from '../../Assets/img/leads/fig-2/env.svg';

import cbeli from '../../Assets/img/leads/col-1/beli.svg';
import coli from '../../Assets/img/leads/col-1/col.svg';
import happy from '../../Assets/img/leads/col-1/happy.webp';
import cseli from '../../Assets/img/leads/col-1/seli.svg';
import tvp from '../../Assets/img/leads/col-1/tvp.svg';

class Col1 extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render () {
        return (
            <div className={"figger fic-1"+(this.props.className ? " "+this.props.className : "")}>
                <figure className="fig-1">
                    <img src={cbeli} className="cbeli" alt="" />
                    <img src={coli} className="coli" alt="" />
                    <img src={happy} className="happy" alt="" />
                    <img src={cseli} className="cseli" alt="" />
                    <img src={tvp} className="tvp" alt="" />
                </figure>
            </div>
        );
    }
}

class Col2 extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render () {
        return (
            <div className={"figger fic2"+(this.props.className ? " "+this.props.className : "")}>
                <figure className="fig-2">
                    <img src={bl} className="fbl" alt="" />
                    <img src={fl} className="ffl" alt="" />
                    <img src={he} className="fhe" alt="" />
                    <img src={md} className="fmd is-desktop" alt="" />
                    <img src={md} className="fmd is-tablet" alt="" />
                    <img src={mdm} className="fmd is-mobile" alt="" />
                    <img src={p1} className="fp1" alt="" />
                    <img src={ph} className="fph" alt="" />
                    <img src={tb} className="ftb" alt="" />
                    <img src={pl} className="fpl is-mobile" alt="" />
                </figure>
            </div>
        );
    }
}

class Col4 extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render () {
        return (
            <div className={"figger fic4"+(this.props.className ? " "+this.props.className : "")}>
                <figure className='fig-4'>
                    <img src={beli} className="beli" alt="" />
                    <img src={seli} className="seli" alt="" />
                    <img src={flw} className="flw" alt="" />
                    <img src={lm1} className="lm1" alt="" />
                    <img src={lm2} className="lm2" alt="" />
                    <img src={shu} className="shu" alt="" />
                </figure>
            </div>
        );
    }
}

class Fig2 extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render () {
        return (
            <div className={"pigger pig2"+(this.props.className ? " "+this.props.className : "")}>
                <figure className='pig-2'>
                    <img src={lap} className="lap_0" alt="" />
                    <img src={_env} className="env_0" alt="" />
                    <img src={_env} className="env_1" alt="" />
                    <img src={_env} className="env_2" alt="" />
                    <img src={_env} className="env_3" alt="" />
                </figure>
            </div>
        );
    }
}

class Fig3 extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render () {
        return (
            <div className={"pigger pig3"+(this.props.className ? " "+this.props.className : "")}>
                <figure className='pig-3'>
                    <img src={pho} className="pho" alt="" />
                    {/* <img src={rey} className="rob" alt="" />
                    <img src={lye} className="ey-1" alt="" />
                    <img src={rye} className="ey-2" alt="" /> */}
                    <img src={rwy} className="rob" alt="" />
                    <div className='white-flame-body'>
                        <div className="flame-container">
                            <div className="red flame"></div>
                            <div className="orange flame"></div>
                            <div className="yellow flame"></div>
                            <div className="white flame"></div>
                        </div>
                    </div>
                </figure>
            </div>
        );
    }
}

export {Col1, Col2, Col4, Fig2, Fig3}