import $ from 'jquery';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Page from './Page';
import Monalisa from '../Hero/Monalisa';
import { Col1, Col2, Col4, Fig2, Fig3 } from '../Motion/Fig';
import {_ref, _space, _dvar, _menu } from '../../utils';

// PAGE
let page = 'leads';
let sdf = null;
let sdf2 = null;
let md_sdf = null;
let md_sdf2 = null;
let sm_sdf = null;
let sm_sdf2 = null;
let rock_light = null;
let rock_dark = null;
let s1_dark = null;
let s1_light = null;
let s2_dark = null;
let s2_light = null;
let s3_dark = require("../../Assets/img/"+page+"/s3-dark.webp")["default"];
let s3_light = require("../../Assets/img/"+page+"/s3-light.webp")["default"];
let s4_dark = null;
let s4_light = null;
let t1_dark = require("../../Assets/img/"+page+"/t1-dark.webp")["default"];
let t1_light = require("../../Assets/img/"+page+"/t1-light.webp")["default"];
let t2_dark = null;
let t2_light = null;
let t3_dark = null;
let t3_light = null;

const assets = {
    Hero: Monalisa,
    Col1: Col1,
    Col2: Col2,
    Col3: null,
    Col4: Col4,
    Part1: null,
    Part2: Fig2,
    Part3: Fig3,
    sdf, sdf2, md_sdf, md_sdf2, sm_sdf, sm_sdf2, rock_light, rock_dark, s1_dark, s1_light, s2_dark, s2_light, s3_dark, s3_light, s4_dark, s4_light, t1_dark, t1_light, t2_dark, t2_light, t3_dark, t3_light
};

const {fps, fpt, _brs, _tln_} = _dvar();

const PAGE_DATA = {
    "cta": <>Apply Now &#8594;</>,
    "col_cta": <>Apply Now &#8594;</>,
    "mastext": (<p className="f22">We generate over 600+ hot leads <br className='is-mobile' />every month for{_space()}service businesses, <br className='is-mobile' />agencies and tech startups.</p>),
    "style_1": null,
    "style_2": {top: "-30px"},
    "style_3": null,
    "style_4": {top: "-30px", marginBottom: "3em"},
    "hiw_1": ['Click "Get leads" button', 'Click the "Get leads now" button to reveal our pricing page where you can place an order.'],
    "hiw_2": ["Place an order", "Make an order of at least 5 hot leads on the pricing page for best conversion results."],
    "hiw_3": ["7 Days Delivery", "We’ll have your lead generation campaign setup and delivering leads in under 7 days."],
    "hiw_4": ["Start calling Leads", "Kpimatrix will deliver super qualified leads and all you have to do is call and close the deal."],
    "col_1": (<>
        <h1 className="text-black font-weight-bold f36" style={{marginBottom: "14px"}}>Attract quality Leads for{_space()}your business</h1>
        <p className="pbold f20">With powerful offers that convert like crazy</p>
        <p className="pbold f16">Weak offers Like <b>“buy x, y or z” or “get it for 15% off”</b> don't work anymore. These are basic offers that do not attract quality customers.</p>
        <p className="pbold f16">At Kpimatrix, we engineer simple but efficient offers that will{_space()}have you converting the highest quality Leads in no time.</p>
    </>),
    "col_2": (<>
        <h1 className="text-black font-weight-bold f36" style={{marginBottom: "14px"}}>We use eye-catching <br className="is-desktop" />creatives that convert</h1>
        <p className="pbold f20">A picture is worth a thousand words.</p>
        <p className="pbold f16">Our team will design targeted creatives that are{_space()}tailor made to grab the attention of your target{_space()}audience and help you convert more.</p>
        <p className="pbold f16">The key to a great creative is understanding the{_space()}emotional response in relation to your ideal customer.{_space()}In other words, how do you make them feel?</p>
    </>),
    "col_3": (<>
        <h1 className="text-black font-weight-bold f36" style={{marginBottom: "14px"}}>We will optimize your sales pages to convert more</h1>
        <p className="pbold f20">Get verified contacts with next gen lead forms</p>
        <p className="pbold f16">Kpimatrix handles everything from direct response{_space()}copywriting to integrating your landing page with a sales{_space()}CRM.</p>
        <p className="pbold f16">We will optimize your existing web pages with next gen lead{_space()}forms to increase conversions and sell your offers in a way{_space()}that truly represents your brand.</p>
    </>),
    "col_4": (<>
        <h1 className="text-black font-weight-bold f36" style={{marginBottom: "14px"}}>Unlock the floodgate of{_space()}Hot leads from killer Ads</h1>
        <p className="pbold f20">Attention is the most important currency in the{_space()}online world.</p>
        <p className="pbold f16">Our goal is to break the social media scrolling pattern and{_space()}keep your target audience engaged long enough to{_space()}turn them into loyal customers.</p>
        <p className="pbold f16">At Kpimatrix, we launch Ads across multiple social media{_space()}channels so that you get the highest quality leads{_space()}for your business even while you sleep.</p>
    </>),
    "with": (<>With Kpimatrix as a Lead <br className='is-smalls' />generation partner,{_space()}Your <br className='is-smalls' />company can:</>),
    "partner_1": (<>
        <strong className="f24" style={fps}>Generate a ton of super qualified leads</strong>
        <p className="pbold f16" style={fpt}>
            Getting a consistent supply of qualified leads is the{_space()}lifeblood of any business. With quality leads you can{_space()}consistently sell your offers to people who genuinely{_space()}need them.<br /><br />
            We help your business increase revenue everyday by{_space()}consistently delivering qualified leads that have a real{_space()}need for the solution your company offers and can afford it.
        </p>
    </>),
    "partner_2": (<>
        <strong className="f24" style={fps}>Keep Leads Engaged via Email <br className='is-desktop' />Sequences</strong>
        <p className="pbold f16" style={fpt}>
            It is one thing to get people looking at your brand and{_space()}it is a completely different ball game to keep people{_space()}interested in your product or services.<br /><br />
            Everyone loves to window shop and gather information{_space()}before making a purchase. We help you retain the{_space()}attention of window shoppers long enough to convert{_space()}them into loyal customers through well crafted emails{_space()}that educate and sell.
        </p>
    </>),
    "partner_3": (<>
        <strong className="f24" style={fps}>Close more deals over the Phone & with{_space()}Automation</strong>
        <p className="pbold f16" style={fpt}>
            We know that the biggest deals are closed over the{_space()}phone. 
            Kpimatrix provides you with all the data your{_space()}sales team needs to jump on multiple calls and{_space()}close as many deals as possible.<br /><br />
            Alternatively, we can set up an automated sales funnel{_space()}that generates sales without ever having to pick up a phone.
        </p>
    </>),
    "t_title": (<>You Only Pay Per Qualified Lead</>),
    "t_subtitle": (<>At Kpimatrix we work on a results based model. Our pricing model is based on Key performance <br className='is-desktop'/>indicators hence, you only pay for performance and what you need.</>),
    "t_rows": [
        (<tr><th>INDUSTRY</th><th>GEO Location</th><th>Avg.Cost Per<br />Lead</th><th>Avg.<br className='is-tablet' />Conv<span className='is-tablet'>ersion</span><br className='is-tablet'/> Rate</th><th>Avg.Customer<br />Value</th></tr>),
        _tln_(0),
        (<tr className='is-mobile'>
            <td className='flx'><b className='sm is-mobile'>INDUSTRY</b><span>CONSUMER SERVICES</span></td> 
        </tr>),
        (<tr className='is-mobile'>
            <td className='flx ft12'><b className='sm is-mobile'>GEO Location</b><span className='geo-sp'>Africa, Asia, Caribbean, Central America, Europe, North America, Oceania</span></td>
        </tr>),
        (<tr>
            <th className='flx not-mobile'><b className='sm is-mobile'>INDUSTRY</b><span>CONSUMER<br />SERVICES</span></th>
            <td className='flx ft12 not-mobile'><b className='sm is-mobile'>GEO Location</b><span className='geo-sp'>Africa, Asia, Caribbean,<br />Central America, Europe,<br />North America, Oceania</span></td>
            <td className='flx-3'><b className='sm is-mobile'>Avg.<br />Cost Per Lead</b><span>€250</span></td><td className='flx-3'><b className='sm is-mobile'>Avg.<br />Conversion Rate</b><span>30%</span></td><td className='flx-3'><b className='sm is-mobile'>Avg.<br />Customer LTV</b><span>€2000</span></td>
        </tr>),
        _tln_(),
        (<tr className='is-mobile'>
            <td className='flx'><b className='sm is-mobile'>INDUSTRY</b><span>FINANCIAL SERVICES</span></td> 
        </tr>),
        (<tr className='is-mobile'>
            <td className='flx ft12'><b className='sm is-mobile'>GEO Location</b><span className='geo-sp'>Africa, Asia, Caribbean, Central America, Europe, North America, Oceania</span></td>
        </tr>),
        (<tr>
            <th className='flx not-mobile'><b className='sm is-mobile'>INDUSTRY</b><span>FINANCIAL<br />SERVICES</span></th>
            <td className='flx ft12 not-mobile'><b className='sm is-mobile'>GEO Location</b><span className='geo-sp'>Africa, Asia, Caribbean,<br />Central America, Europe,<br />North America, Oceania</span></td>
            <td className='flx-3'><b className='sm is-mobile'>Avg.<br />Cost Per Lead</b><span>€500</span></td><td className='flx-3'><b className='sm is-mobile'>Avg.<br />Conversion Rate</b><span>15%</span></td><td className='flx-3'><b className='sm is-mobile'>Avg.<br />Customer LTV</b><span>€5000</span></td>
        </tr>)
    ],
    "faq": _menu("faq")[0],
    "t_small": (<>*** these are estimates based on campaign data from past campaigns. Check pricing page for updated cost ***</>),
    "service": (<>We stayed up all night thinking <br className='is-tablet' /><br className='is-mobile' />about <br className='is-desktop' />all the questions you could <br className='is-tablet' />possibly ask</>),
    "footer": (<>
        <h2 className="text-center mt-0 fl50" style={{fontWeight: 600, marginBottom: "40px"}}>Let’s get the ball rolling right now! <br className='is-tablet' /><br className='is-desktop'/>What say you?</h2>
        <h4 className="text-center mt-0 f24" style={{fontWeight: 400, lineHeight: 1.7}}>We will take care of all the setup and start <br className='is-tablet' /><br className='is-desktop'/>delivering quality leads to your inbox in no time.</h4>
    </>)
};

class Leads extends Component {
    componentDidMount () {
        $("title").html("Kpimatrix ~ Lead Generation");
    }
    render () {
        return <Page assets={assets} page={page} data={PAGE_DATA} />
    }
}

export default withRouter(Leads);